import firebase from 'firebase/compat/app'
import { stringToColor } from './colors'
import { User } from '../types/user'
import { Booking, BookingEvent } from '../types/booking'
import { getDisplayName } from './user'

const getTitle = (displayName: string, item: Booking) => {
  const { people, babies } = item
  const count = [people]
  if (babies) count.push(babies)
  return `${displayName}  —  ${count.join('+')}`
}

/**
 * Return a color scheme based on a string (displayName)
 */
const getColors = (displayName: string) => ({
  main: (displayName ? stringToColor(displayName) : 'lightGrey'), // usually the bg
  contrast: displayName ? stringToColor(displayName, '50%', '20%') : 'black',
})

export const formatBooking = (item: Booking, users: User[]): BookingEvent => {
  if (!item) return item
  const user = firebase.auth().currentUser
  const owner = item.ownerId ? users[item.ownerId as any] : undefined
  const displayName = getDisplayName(owner)

  return {
    ...item,
    displayName,
    allDay: true,
    meta: {
      colors: getColors(displayName),
      title: getTitle(displayName, item),
      isOwn: item.ownerId === user?.uid,
    },
  }
}

/**
 * Format a list of bookings for display
 */
export const formatBookings = ({
  bookings,
  users,
}: {
  bookings: Booking[],
  users: User[],
}) => {
  if (!bookings) return []
  return bookings.map((item) => formatBooking(item, users))
}
