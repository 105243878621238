export default {
  babies: 'bébés',
  people: 'adultes/enfants',
  beds: 'couchages',
  house: {
    room: 'Chambre',
    rooms: 'Chambre(s)',
  },
  tent: {
    room: 'Place',
    rooms: 'Place(s)',
  },
}
