import React from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonGroup } from '@chakra-ui/react'
import Icon from '../Icon'

const CalendarToolbarViews = ({
  view,
  views,
  onView,
  messages,
}) => {
  if (views.length <= 1) return null
  global.onView = onView
  return (
    <ButtonGroup size="sm" isAttached>
      {
        views.map((name) => (
          <Button
            key={name}
            className={view === name ? 'rbc-active' : ''}
            onClick={() => onView(name)}
            icon={messages[name]}
            title={messages[`${name}Label`]}
          >
            <Icon
              name={messages[name]}
              variant="outline"
            />
          </Button>
        ))
      }
    </ButtonGroup>
  )
}

CalendarToolbarViews.propTypes = {
  view: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  messages: PropTypes.object,
  onView: PropTypes.func.isRequired,
}

CalendarToolbarViews.defaultProps = {
  // eslint-disable-next-line react/forbid-prop-types
  messages: PropTypes.object,
}

export default CalendarToolbarViews
