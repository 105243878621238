import firebase from 'firebase/compat/app'
import { docToData, onError, collectionToArray } from './helpers'

/**
 * fix hours to prevent drift errors when set to 00:00
 * convert to firestore TS
 * @param {String|Date} date
 * @returns firebase.firestore.Timestamp
 */
const sanitizeDate = (date) => {
  const d = new Date(date)
  d.setHours(12)
  return firebase.firestore.Timestamp.fromDate(d)
}

const sanitizeDates = (values) => {
  const data = { ...values }
  if (data.start) data.start = sanitizeDate(data.start)
  if (data.end) data.end = sanitizeDate(data.end)
  return data
}

/**
 * Ensure typings are correct and fix hours to start and end
 * @param {string} ownerId
 * @param {BookingCreateInput} values
 * @returns
 */
const sanitizeBookingData = (ownerId, values) => {
  const {
    end,
    babies = 1,
    people = 1,
    rooms = 1,
    type = 'house',
    start,
    comment,
  } = values

  return {
    start: sanitizeDate(start),
    end: sanitizeDate(end),
    type,
    people: Number(people),
    rooms: Number(rooms),
    babies: Number(babies),
    ownerId,
    comment: comment ? String(comment).trim() : '',
  }
}

export const getCollection = ({ placeId }) => {
  if (!placeId) throw new Error('db.dates.collection : placeId is mandatory')
  return firebase.firestore()
    .collection('places')
    .doc(placeId)
    .collection('bookings')
}

export const get = (bookingId) => getCollection()
  .doc(bookingId)
  .get()
  .then(docToData)
  .catch(onError('db.booking.get'))

export const create = async (values) => {
  const uid = firebase.auth().currentUser?.uid
  const { placeId } = values

  const inputData = sanitizeBookingData(uid, values)

  return getCollection({ placeId })
    .add(inputData)
    .then((ref) => ref.get())
    .then(docToData)
    .catch(onError('db.booking.create'))
}

export const update = (uid, placeId, values) => {
  getCollection({ placeId })
    .doc(uid)
    .set(sanitizeDates(values), { merge: true })
    .catch(onError('db.booking.update'))
}

export const remove = (uid, placeId) => getCollection({ placeId })
  .doc(uid)
  .delete()
  .catch(onError('db.booking.delete'))

export const list = async ({ placeId, start /* , end */ }) => {
  let ref = getCollection({ placeId })

  // Firestore does not support filtering inequalities on more than one field
  // So we can only filter one on firestore and the second one client side
  if (start) ref = ref.where('end', '>=', start)
  // if (end) ref = ref.where('start', '<=', end)

  return ref
    .get()
    .then(collectionToArray)
    .catch(onError('db.booking.list'))
}
