import React from 'react'
import { createIconSet } from './helpers'

export const people = createIconSet(
  'people',
  <g>
    <path d="M41.713 12.2932H41.3655H30.5058H30.1583C28.1601 12.2932 26.5528 13.9005 26.5528 15.8986V27.8009C26.5528 29.7991 28.1601 31.4063 30.1583 31.4063H30.2886V44.3946C30.2886 46.4362 31.3745 48 32.808 48H39.0632C40.4967 48 41.5827 46.4362 41.5827 44.3946V31.4063H41.713C43.7112 31.4063 45.3184 29.7991 45.3184 27.8009V15.8986C45.3184 13.9005 43.7112 12.2932 41.713 12.2932Z" />
    <path d="M21.4705 34.838L19.0813 31.276C20.6451 30.8851 21.818 29.4516 21.818 27.7575V15.8986C21.818 13.9005 20.2107 12.2932 18.2126 12.2932H17.865H7.00531H6.61436C4.61617 12.2932 3.00893 13.9005 3.00893 15.8986V27.8009C3.00893 29.495 4.18178 30.8851 5.74558 31.3195L3.22613 35.0552C2.96549 35.4462 2.92205 35.924 3.13925 36.3584C3.35644 36.7493 3.79083 37.01 4.22522 37.01H6.74468V44.438C6.74468 46.4796 7.83065 48.0434 9.26413 48.0434H15.4759C16.9094 48.0434 17.9954 46.4796 17.9954 44.438V36.9665H20.5148C21.2098 36.9665 21.7745 36.4018 21.7745 35.7068C21.818 35.3593 21.6877 35.0552 21.4705 34.838Z" />
    <path d="M12.3917 0C8.48224 0 5.26776 3.17104 5.26776 7.12398C5.26776 11.0769 8.4388 14.248 12.3917 14.248C16.3447 14.248 19.5157 11.0769 19.5157 7.12398C19.5157 3.17104 16.3447 0 12.3917 0Z" />
    <path d="M35.9356 0C31.9827 0 28.8116 3.17104 28.8116 7.12398C28.8116 11.0769 31.9827 14.248 35.9356 14.248C39.8886 14.248 43.0596 11.0769 43.0596 7.12398C43.0596 3.17104 39.8886 0 35.9356 0Z" />
  </g>,
  <g>
    <path fillRule="evenodd" clipRule="evenodd" d="M30.5526 27.4149C32.6386 27.551 34.2884 29.286 34.2884 31.4063V44H37.5825V31.4063C37.5825 29.286 39.2323 27.551 41.3183 27.4149V16.2932H30.5526V27.4149ZM41.7128 12.2932C43.711 12.2932 45.3183 13.9005 45.3183 15.8986V27.8009C45.3183 29.7991 43.711 31.4063 41.7128 31.4063H41.5825V44.3946C41.5825 46.4362 40.4965 48 39.063 48H32.8078C31.3744 48 30.2884 46.4362 30.2884 44.3946V31.4063H30.1581C28.1599 31.4063 26.5526 29.7991 26.5526 27.8009V15.8986C26.5526 13.9005 28.1599 12.2932 30.1581 12.2932H41.7128Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20.5146 36.9665C21.2097 36.9665 21.7744 36.4018 21.7744 35.7068C21.8178 35.3593 21.6875 35.0552 21.4703 34.838L19.0811 31.276C19.8978 31.0719 20.6078 30.5834 21.0998 29.922C21.55 29.3169 21.8178 28.5669 21.8178 27.7575V15.8986C21.8178 13.9005 20.2106 12.2932 18.2124 12.2932H6.61418C4.61599 12.2932 3.00875 13.9005 3.00875 15.8986V27.8009C3.00875 28.6178 3.28145 29.364 3.73918 29.9664C4.23073 30.6134 4.93565 31.0945 5.7454 31.3195L3.22594 35.0552C2.96531 35.4462 2.92187 35.924 3.13906 36.3584C3.35626 36.7493 3.79065 37.01 4.22504 37.01H6.74449V44.438C6.74449 46.4796 7.83047 48.0434 9.26395 48.0434H15.4757C16.9092 48.0434 17.9952 46.4796 17.9952 44.438V36.9665H20.5146ZM8.9729 33.6877C10.0414 34.4058 10.7445 35.6258 10.7445 37.01V44.0434H13.9952V36.9665C13.9952 35.5566 14.7246 34.3171 15.8267 33.6048L15.7592 33.5042C15.0335 32.4222 14.8802 31.0547 15.3482 29.8389C15.7796 28.7185 16.6887 27.8564 17.8178 27.4807V16.2932H7.00875V27.5242C8.15837 27.9068 9.07676 28.7921 9.49841 29.9357C9.94338 31.1425 9.78088 32.4896 9.06169 33.556L8.9729 33.6877Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.3916 4C10.6753 4 9.26758 5.39604 9.26758 7.12398C9.26758 8.86778 10.6478 10.248 12.3916 10.248C14.1354 10.248 15.5155 8.86779 15.5155 7.12398C15.5155 5.38018 14.1354 4 12.3916 4ZM5.26758 7.12398C5.26758 3.17104 8.48206 0 12.3916 0C16.3445 0 19.5155 3.17104 19.5155 7.12398C19.5155 11.0769 16.3445 14.248 12.3916 14.248C8.43862 14.248 5.26758 11.0769 5.26758 7.12398Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M35.9354 4C34.1916 4 32.8115 5.38018 32.8115 7.12398C32.8115 8.86778 34.1916 10.248 35.9354 10.248C37.6792 10.248 39.0594 8.86779 39.0594 7.12398C39.0594 5.38018 37.6792 4 35.9354 4ZM28.8115 7.12398C28.8115 3.17104 31.9825 0 35.9354 0C39.8884 0 43.0594 3.17104 43.0594 7.12398C43.0594 11.0769 39.8884 14.248 35.9354 14.248C31.9825 14.248 28.8115 11.0769 28.8115 7.12398Z" />
  </g>,
)
