import { combineReducers } from 'redux'
import resetEnhancer from './resetEnhancer'

import { auth } from './auth'
import { place } from './place'
import { booking } from './booking'
import { user } from './user'
import { resource } from './resource'

const rootReducer = combineReducers({
  auth,
  booking,
  place,
  resource,
  user,
})

export default resetEnhancer(rootReducer)
