import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@chakra-ui/react'
import Calendar from '../../components/Calendar'
import BookingModal from './Booking.Modal'
import usePlace from '../../hooks/usePlace'
import { LoadingOverlay } from '../../components/Loading'
import Header from './Place.Header'

const PlacePage = () => {
  const { placeId } = useParams()
  const [editing, setEditing] = useState(false)
  const [values, setValues] = useState({})
  const { place, loading } = usePlace(placeId, { withUsers: true })

  const openModal = (initialValues = {}) => {
    setValues(initialValues)
    setEditing(true)
  }

  const closeModal = () => {
    setEditing(false)
    setValues({})
  }

  if (loading) return <LoadingOverlay />
  if (!place) {
    return (
      <Box>
        404
      </Box>
    )
  }

  return (
    <Box>
      <Header place={place} />

      {
        editing && (
          <BookingModal
            open={editing}
            onClose={closeModal}
            placeId={placeId}
            values={values}
          />
        )
      }

      <Calendar
        onEventClick={({ uid }) => openModal({ uid })}
        onEventAdd={({ start, end }) => openModal({ start, end })}
        placeId={placeId}
        place={place}
      />
    </Box>
  )
}

export default PlacePage
