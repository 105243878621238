import React, { FC } from 'react'
import { Flex, Spinner, SpinnerProps } from '@chakra-ui/react'
import { string } from 'prop-types'

export interface LoadingOverlayProps extends SpinnerProps { }

const LoadingOverlay: FC<LoadingOverlayProps> = (props) => (
  <Flex
    width="100%"
    height="100%"
    alignItems="center"
    justifyContent="center"
  >
    <Spinner {...props} />
  </Flex>
)

LoadingOverlay.propTypes = {
  color: string,
  thickness: string,
  speed: string,
  emptyColor: string,
  size: string,
}

LoadingOverlay.defaultProps = {
  thickness: '4px',
  speed: '0.65s',
  emptyColor: 'gray.200',
  color: 'blue.500',
  size: 'xl',
}

export default LoadingOverlay
