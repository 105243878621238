import BaseValue from './Booking.Values.Base'
import ValueType from './Booking.Values.Type'
import ValueNumber from './Booking.Values.Number'
import ValueDate from './Booking.Values.Date'
import ValuePeriod from './Booking.Values.Period'

export default {
  Base: BaseValue,
  Type: ValueType,
  Number: ValueNumber,
  Date: ValueDate,
  Period: ValuePeriod,
}
