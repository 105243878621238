import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  SimpleGrid,
} from '@chakra-ui/react'
import firebase from 'firebase/compat/app'
import { useHistory } from 'react-router-dom'
import { UserProfile } from '../../types'

const schema = yup.object({
  people: yup.number().min(1).max(20).required(),
  babies: yup.number().min(0).max(40).integer(),
  displayName: yup.string().min(4).max(40).required(),
  email: yup.string().email().disabled,
}).required()

/**
 * Current user only
 * @param {*} param0
 * @returns
 */
const ProfileForm = ({
  onSubmit,
  userProfile,
}) => {
  const history = useHistory()
  const { email } = firebase.auth().currentUser
  const {
    people = 1,
    babies = 0,
    displayName,
  } = userProfile

  const {
    register,
    handleSubmit,
    formState: { errors },
    isSubmitting,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      displayName,
      people,
      babies,
      email,
    },
  })

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>

        <SimpleGrid gridTemplateColumns="1fr auto 1fr" gridGap={4}>
          <Box>
            <Heading size="md" mb={4}>Vous</Heading>
            <FormControl isInvalid={errors.displayName} mb="4">
              <FormLabel>Nom</FormLabel>
              <Input {...register('displayName')} />
              <FormErrorMessage>
                {errors.displayName ? errors.displayName.message : ''}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.email} mb="4">
              <FormLabel>e-mail (non modifiable)</FormLabel>
              <Input disabled {...register('email')} />
              <FormErrorMessage>
                {errors.email ? errors.email.message : '&nbsp;'}
              </FormErrorMessage>
            </FormControl>
          </Box>

          <Divider orientation="vertical" />

          <Box>
            <Heading size="md" mb={4}>Votre foyer</Heading>
            <FormControl isInvalid={errors.people} mb="4">
              <FormLabel>Nombre de personnes de plus de 3ans</FormLabel>
              <Input type="number" {...register('people')} />
              <FormErrorMessage>
                {errors.people ? errors.people.message : ''}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.babies} mb="4">
              <FormLabel>Nombre d&apos;enfants de moins de 3ans</FormLabel>
              <Input type="number" {...register('babies')} />
              <FormErrorMessage>
                {errors.babies ? errors.babies.message : ''}
              </FormErrorMessage>
            </FormControl>
          </Box>
        </SimpleGrid>

        <ButtonGroup mt={4}>
          <Button
            variant="outline"
            onClick={() => history.push('/')}
          >
            Retour
          </Button>
          <Button
            isLoading={isSubmitting}
            type="submit"
          >
            Enregistrer
          </Button>
        </ButtonGroup>
      </form>
    </Box>
  )
}

ProfileForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  userProfile: UserProfile.isRequired,
}

export default ProfileForm
