import React from 'react'
import { Box } from '@chakra-ui/react'
import { string, bool, node } from 'prop-types'
import LoadingFullpage from './Loading.fullpage'

const LoadingWrapper = ({ loading, children, ...rest }) => (
  <Box position="relative">
    {children}
    {
      loading ? (
        <LoadingFullpage
          position="absolute"
          background="rgba(255,255,255,.5)"
          {...rest}
        />
      ) : null
    }
  </Box>
)

LoadingWrapper.propTypes = {
  children: node,
  loading: bool.isRequired,
  color: string,
  thickness: string,
  speed: string,
  emptyColor: string,
  size: string,
}

LoadingWrapper.defaultProps = {
  children: null,
  thickness: '4px',
  speed: '0.65s',
  emptyColor: 'gray.200',
  color: 'blue.500',
  size: 'xl',
}

export default LoadingWrapper
