/* eslint-disable import/prefer-default-export */
import {
  string,
  number,
  shape,
  arrayOf,
  instanceOf,
} from 'prop-types'

/** EVERYTHING BELOW IS PROPTYPES, NOT TYPES */

export const DateType = instanceOf(Date)

export const User = shape({
  uid: string.isRequired,
  email: string.isRequired,
  displayName: string,
  photoURL: string,
})

export const UserProfile = shape({
  uid: string.isRequired,
  displayName: string,
  photoURL: string,
  people: number,
  babies: number,
})

export const Owner = shape({
  uid: string,
  displayName: string,
})

export const Place = shape({
  uid: string.isRequired,
  name: string.isRequired,
  rooms: number.isRequired,
  tents: number.isRequired,
  peopleRoom: number,
  peopleTent: number,
  bgUrl: string,
  owner: Owner,
})

export const Booking = shape({
  uid: string.isRequired,
  start: DateType.isRequired,
  end: DateType.isRequired,
  type: string.isRequired,
  people: number.isRequired,
  rooms: number.isRequired,
  babies: number,
  places: arrayOf(string),
  owner: Owner,
})

export const BookingCreate = shape({
  start: DateType,
  end: DateType,
  type: string,
  people: number,
  rooms: number,
  babies: number,
})
