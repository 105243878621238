/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import './style.css'

const MONTHS = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
]
const WEEKDAYS_LONG = [
  'Dimanche',
  'Lundi',
  'Mardi',
  'Mercredi',
  'Jeudi',
  'Vendredi',
  'Samedi',
]
const WEEKDAYS_SHORT = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa']

const Picker = (props) => (
  <DayPicker
    locale="fr"
    months={MONTHS}
    weekdaysLong={WEEKDAYS_LONG}
    weekdaysShort={WEEKDAYS_SHORT}
    firstDayOfWeek={1}
    {...props}
  />
)

export default Picker
