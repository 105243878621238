/* eslint-disable import/prefer-default-export */
import firebase from 'firebase/compat/app'
import { SET_RESOURCE } from './types'
import db from '../../../init/db'

/**
 *
 * @param {Object} payload   - { placeId, bookings }
 * @returns
 */
export const setResource = (payload) => ({
  type: SET_RESOURCE,
  payload,
})

export function setFromPath({ path }) {
  if (!path) return null
  const storageRef = firebase.storage().ref(path)
  return async (dispatch) => {
    try {
      const url = await storageRef.getDownloadURL()
      dispatch(setResource({ uid: path, url }))
    } catch (e) {
      db.helpers.onError('useStoragePath', e)
    }
  }
}
