import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import store from '../store'
import { authenticate, repudiate } from '../store/modules/auth/actions'
import { addUser } from '../store/modules/user/actions'
import { checkProfile } from './db/user'

const onUserAuthenticate = async (userAuth) => {
  const profile = await checkProfile()
  store.dispatch(addUser(profile))
  store.dispatch(authenticate(userAuth))
}

const onUserRepudiate = async () => {
  store.dispatch(repudiate())
  store.dispatch({
    type: 'RESET_STORE',
  })
}

const initAuth = () => {
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

  firebase.auth().onAuthStateChanged(
    (userAuth) => {
      if (userAuth) onUserAuthenticate(userAuth)
      else onUserRepudiate()
    },
  )

  /**
     * Enable emulators
     */
  if (process.env.REACT_APP_FIREBASE_AUTH_EMULATOR === '1') {
    // eslint-disable-next-line no-console
    console.warn('🙈 --- running auth emulator')
    firebase.auth().useEmulator('http://localhost:9099')
  }
}

export default initAuth
