import React, { useCallback, useState } from 'react'
import { Calendar as BigCalendar } from 'react-big-calendar'
import PropTypes from 'prop-types'
import { useBreakpointValue } from '@chakra-ui/react'
import { Booking, Place } from '../../types'
import Toolbar from './Calendar.Toolbar'
import Legend from './Calendar.Legend'
import useBookingSnapshot from '../../hooks/useBookingSnapshot'
import { localizer, MESSAGES, formats } from './localizer'
import useCalendarDate from './useCalendarDate'
import usePropGetters from './usePropGetters'
import CalendarEvent from './Calendar.Event'
import ReportView from './Calendar.ReportView'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import './style.css'

const Calendar = ({
  place,
  placeId,
  events,
  height,
  onEventClick,
  onEventAdd,
}) => {
  const { date, range, setDate } = useCalendarDate()
  const [view, setView] = useState('month')

  const compact = useBreakpointValue({ base: true, md: false })

  useBookingSnapshot({ placeId })
  const { dayPropGetter } = usePropGetters(place, range)

  const onNavigate = useCallback(
    (newDate/* , currentView, action */) => {
      // Prevent 2 month jump when next month has less days than current
      const d = new Date(newDate)
      d.setDate(1)
      setDate(d)
    },
    [setDate],
  )

  return (
    <>
      <BigCalendar
        selectable
        className="booking-calendar drop-top-left-xl"
        localizer={localizer}
        events={events}
        view={view}
        date={date}
        // views={['month', 'agenda']}
        views={{
          month: true,
          report: ReportView,
        }}
        getDrilldownView={() => { }}
        style={{ minHeight: height }}
        onNavigate={onNavigate}
        onView={setView}
        onSelectEvent={onEventClick}
        onSelectSlot={onEventAdd}
        dayPropGetter={dayPropGetter}
        messages={MESSAGES}
        formats={formats}
        components={{
          toolbar: (props) => (
            <Toolbar
              {...props}
              onEventAdd={onEventAdd}
              compact={compact}
            />
          ),
          agenda: {
            time: () => (<span>•</span>),
          },
          month: { event: CalendarEvent },
        }}
        showAllEvents
      />
      <Legend />
    </>
  )
}

Calendar.propTypes = {
  placeId: PropTypes.string.isRequired,
  place: Place,
  events: PropTypes.arrayOf(Booking),
  onEventClick: PropTypes.func,
  onEventAdd: PropTypes.func,
  height: PropTypes.number,
}

Calendar.defaultProps = {
  place: null,
  events: [],
  height: 1000,
  onEventClick: null,
  onEventAdd: null,
}

export default Calendar
