/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, ButtonGroup } from '@chakra-ui/react'
import { propTypes } from './types'

const BookingFormFooter = ({
  formState,
  stepper,
  isValid,
}) => (
  <Box py="4" textAlign="center">
    <ButtonGroup spacing="4">
      {
        stepper.canMove(-1) ? (
          <Button
            variant="link"
            onClick={() => stepper.move(-1)}
          >
            Précédent
          </Button>
        ) : null
      }

      {
        stepper.canMove(1) ? (
          <Button
            onClick={() => stepper.move(1)}
            disabled={!isValid}
          >
            Suivant
          </Button>
        ) : (
          <Button
            type="submit"
            disabled={!(isValid && formState.isValid)}
          >
            Enregistrer
          </Button>
        )
      }

    </ButtonGroup>
  </Box>
)

BookingFormFooter.propTypes = {
  formState: PropTypes.object.isRequired,
  isValid: PropTypes.bool,
  stepper: PropTypes.shape({
    get: propTypes.func,
    move: propTypes.func,
    canMove: propTypes.func,
  }).isRequired,
}

BookingFormFooter.defaultProps = {
  isValid: true,
}

export default BookingFormFooter
