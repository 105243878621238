import { ADD_USER, ADD_USERS } from './types'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_USERS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case ADD_USER: {
      return {
        ...state,
        [action.payload.uid]: action.payload,
      }
    }
    default:
      return state
  }
}
