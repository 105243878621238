import PropTypes from 'prop-types'

export const valuesShape = PropTypes.shape({
  type: PropTypes.string,
  rooms: PropTypes.number,
  people: PropTypes.number,
  babies: PropTypes.number,
  comment: PropTypes.string,
  dates: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
})

export const defaultValues = {
  type: '',
  rooms: 1,
  people: 1,
  babies: 0,
  comment: '',
  dates: {
    start: undefined,
    end: undefined,
  },
}

export const propTypes = {
  onBack: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  trigger: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,
}

export const defaultProps = {

}
