import {
  Box, Flex, Heading,
} from '@chakra-ui/react'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { LoadingOverlay } from '../../components/Loading'
import { UserContext } from '../../context/UserProvider'
import { getUser } from '../../store/modules/user/selectors'
import { PlaceCode } from '../../components/Place'

/*
If user has no place, ask for the "passcode", aka secret <-> placeId
Else if user has only one place -> redirect to it
Else Allow to choose a place
*/

const RegisterPage = () => {
  const user = useContext(UserContext)
  const profile = useSelector(
    (state) => getUser(state, user?.uid),
  )

  if (!profile) return <LoadingOverlay />

  return (
    <Flex alignItems="center" justifyContent="center" textAlign="center">
      <Box>
        <Heading as="h1" size="lg">
          Rejoindre une propriété
        </Heading>

        <PlaceCode margin={8} />
      </Box>
    </Flex>
  )
}

export default RegisterPage
