import { dateFnsLocalizer } from 'react-big-calendar'
import { parse, startOfWeek, getDay } from 'date-fns'
import { fr } from 'date-fns/locale'
import { format } from '../../helpers/date'

const locales = { fr }

export const MESSAGES = {
  allDay: '',
  previous: '<<',
  next: '>>',
  today: 'calendar-day', // icon name
  todayLabel: 'Aujourd\'hui', // icon name
  day: '', // not used
  week: '', // not used
  month: 'calendar-table', // icon name
  monthLabel: 'Calendrier',
  // agenda: 'calendar-list-outline', // icon name
  report: 'calendar-moon', // icon name
  reportLabel: 'Nuitées',
  // month: 'calendar', // iconset name
  // report: 'moon', // iconset name
  date: 'Date',
  time: '',
  event: '',
  showMore: (total: Number) => `+ ${total}`,
  reportTitle: (date: Date) => `Nuits ${format(date, 'MMM yy')}`,
}

export const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: (date: Date) => startOfWeek(date, { weekStartsOn: 1 }),
  getDay,
  locales,
  messages: MESSAGES,
})

export const formats = {
  monthHeaderFormat: (date: Date) => format(date, 'MMMM yyyy'),
}

export type Localizer = typeof localizer
