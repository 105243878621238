import { Box, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import {
  LOAD_STATUS_OVERLOAD,
  LOAD_STATUS_FULL,
  LOAD_STATUS_HIGH,
  LOAD_STATUS_MEDIUM,
} from '../../constants/booking'

const statuses = [
  {
    id: LOAD_STATUS_OVERLOAD,
    label: 'Surcharge !',
  },
  {
    id: LOAD_STATUS_FULL,
    label: 'Chargé',
  },
  {
    id: LOAD_STATUS_HIGH,
    label: 'Ça va...',
  },
  {
    id: LOAD_STATUS_MEDIUM,
    label: 'Tranquille',
  },
]

const CalendarLegend = () => (

  <Stack
    marginY={4}
    className="booking-calendar booking-calendar-legend"
    direction={['column', 'row']}
    fontSize="sm"
  >
    <Text fontWeight="bold">Remplissage </Text>
    {
      statuses.map(
        ({ id, label }) => (
          <Box
            className={`day-status day-status-${id}`}
            key={id}
          >
            {label}
          </Box>
        ),
      )
    }
  </Stack>
)

export default CalendarLegend
