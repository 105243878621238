import React from 'react'
import { Box } from '@chakra-ui/react'
import LoadingOverlay, { LoadingOverlayProps } from './Loading.overlay'

interface LoadingFullpageProps {
  position?: 'fixed' | 'absolute';
  background?: string;
  zIndex?: number | string;
}

const LoadingFullpage = ({
  position = 'fixed',
  background,
  ...rest
}: LoadingFullpageProps & LoadingOverlayProps) => (
  <Box
    position={position}
    background={background}
    inset="0"
  >
    <LoadingOverlay {...rest} />
  </Box>
)

export default LoadingFullpage
