/* eslint-disable no-console */
/**
 * Logging centralization
 */
export const error = (...args) => console.error(...args)

export const warning = (...args) => console.warn(...args)

/**
 * Allow pipe logging
 * @param {string} subject
 * @returns {function}      - (p) => p
 */
export const interlog = (subject) => (input) => {
  console.log('---', subject, input)
  return input
}
