import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import firebase from 'firebase/compat/app'
import BookingEdit from '../../components/Booking/Edit'
import BookingShow from '../../components/Booking/Show'
import { useBookingValues } from './hooks'

const getTitle = ({
  uid, ownerId, displayName,
}) => {
  const userAuth = firebase.auth().currentUser
  if (!uid) return 'Nouvelle réservation'
  if (userAuth.uid === ownerId) return 'Votre réservation'
  return displayName || 'Réservation'
}

const BookingModal = ({
  open,
  onClose,
  values,
  placeId,
}) => {
  const { uid } = values
  const [editing, setEditing] = useState(!uid)
  const booking = useBookingValues({ placeId, values }, [uid])
  useEffect(
    () => { setEditing(!uid) },
    [uid],
  )

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent
        padding={6}
        margin={[0, 4]}
        height={['100%', 'auto']}
      >
        <ModalHeader
          textAlign="center"
          fontSize="3xl"
        >
          {getTitle(booking)}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          overflowY={['auto', 'inherit']}
          padding={0}
        >
          {
            editing
              ? (
                <BookingEdit
                  placeId={placeId}
                  values={booking}
                  onCancel={onClose}
                  onSuccess={onClose}
                />
              )
              : (
                <BookingShow
                  placeId={placeId}
                  values={booking}
                  onCancel={onClose}
                  onSuccess={onClose}
                  onEdit={() => setEditing(true)}
                />
              )
          }
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

BookingModal.propTypes = {
  placeId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  values: PropTypes.shape({
    uid: PropTypes.string,
  }),
}

BookingModal.defaultProps = {
  values: {},
}

export default BookingModal
