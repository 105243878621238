import { useState } from 'react'

import StepType from './Booking.Step.Type'
import StepDetails from './Booking.Step.Details'
import StepDates from './Booking.Step.Dates'

export const STEPS = [
  {
    component: StepType,
    validate: ['type'],
  },
  {
    component: StepDates,
    validate: ['dates'],
  },
  {
    component: StepDetails,
    validate: ['people', 'rooms', 'babies', 'comment'],
  },
]

export const getStep = (index) => STEPS[index]

/**
 * Return the next step index if we want to trigger a move from the current one
 * @param {Number} from
 * @param {Number} move  positive or negative move
 * @return {Number} the next step index if the move is possible
 */
export const moveIndex = (from, move) => {
  const maybe = from + move
  if (maybe < 0) return from
  if (maybe > STEPS.length - 1) return from
  return maybe
}

const useBookingSteps = () => {
  const [index, setIndex] = useState(0)

  return {
    get: () => getStep(index),
    move: (dist) => setIndex(moveIndex(index, dist)),
    canMove: (dist) => {
      const maybe = index + dist
      return (maybe >= 0 && maybe <= STEPS.length - 1)
    },
  }
}

export default useBookingSteps
