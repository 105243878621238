import { loadByDate, filterByRange } from './helpers'
import { formatBookings, formatBooking } from '../../../helpers/booking'

export const getBookings = (store, placeId, range) => {
  if (!placeId) return []
  if (!store.booking[placeId]) return []
  return formatBookings({
    bookings: range
      ? filterByRange(range)(Object.values(store.booking[placeId]))
      : Object.values(store.booking[placeId]),
    users: store.user,
  })
}

/**
 * current implementation expect that place, bookings and place users are already loaded
 * @returns {Booking}
 */
export const getBooking = (store, placeId, bookingId) => formatBooking(
  store.booking[placeId]?.[bookingId],
  store.user,
)

export const getNewBooking = (store, placeId, values) => {
  const { start, end } = values
  const { people = 1, babies = 0 } = store.user[store.auth.uid] || {}
  return {
    start,
    end,
    rooms: 1,
    people,
    babies,
    placeId,
  }
}

export const getRawBookings = (store, placeId) => store.booking[placeId]

export const getDatesLoad = (state, { placeId, start, end }) => {
  const bookings = state.booking[placeId] || {}
  return loadByDate(
    filterByRange({ start, end })(bookings),
  )
}
