/* eslint-disable import/prefer-default-export */
import { ADD_BOOKINGS, REMOVE_BOOKINGS } from './types'
import db from '../../../init/db'

/**
 * @param {Object} payload   - { placeId: string, bookings: Booking[] }
 */
export const addBookings = (payload) => ({
  type: ADD_BOOKINGS,
  payload,
})

/**
 * Remove a batch of bookings from the store based on their ids
 * @param {Object} payload { placeId: string, bookingIds: string[] }
({ placeId, start, end })
 */
export const removeBookings = (payload) => ({
  type: REMOVE_BOOKINGS,
  payload,
})

/**
 * Replace all bookings matching the criterias with the new set of data
 * @param {Object} payload  { placeId: string, start: Date, end: Date })
 * @deprecated ? Currently not relevant because we can not filter by date range
 export const replaceBookings = (payload) => ({
   type: REPLACE_BOOKINGS,
   payload,
  })
*/

export function fetchBookings({ placeId, start, end }) {
  if (!placeId) return null
  return async (dispatch) => {
    // @todo do not refetch if no change => use snapshot ?

    const bookings = await db.booking.list({ placeId, start, end })
    dispatch({ type: ADD_BOOKINGS, payload: { placeId, bookings } })
  }
}
