import React from 'react'
import {
  Box, Flex, FlexProps, SimpleGrid, Text,
} from '@chakra-ui/react'
import {
  DateLocalizer,
} from 'react-big-calendar'
import { DateWithEvents } from './types'
import CalendarEvent from './Calendar.Event'
import Icon, { IconName } from '../Icon'
import { BookingEvent, BookingEventDate } from '../../types/booking'

interface ReportViewProps extends DateWithEvents {
  localizer: DateLocalizer,
  onEventClick: (event: BookingEvent | BookingEventDate) => void,
  dayProps: {
    className?: string,
    houseClassName?: string,
    tentClassName?: string,
  },
  compact?: boolean
}

export const ReportViewRowBase = ({
  children,
  compact,
}: {
  children: React.ReactNode | React.ReactNode[],
  compact?: boolean
}) => {
  const template = compact ? '1fr' : '80px 3fr 1fr'
  return (
    <SimpleGrid
      gridTemplateColumns={template}
      padding="2"
      gap="2"
      borderBottom="1px solid"
      borderBottomColor="lightgray.100"
    >
      {children}
    </SimpleGrid>
  )
}

ReportViewRowBase.defaultProps = {
  compact: false,
}

const ReportViewSum = ({
  icon,
  value,
  compact,
  className,
  ...rest
}: {
  icon?: IconName,
  value: number | undefined
  compact?: boolean
} & FlexProps) => {
  if (compact && !value) return null
  return (
    <Flex
      textAlign="center"
      textColor={value ? 'text.500' : 'text.200'}
      gap="1"
      alignItems="center"
      justifyContent="center"
      borderRadius="md"
      {...rest}
      className={className}
    >
      {
        icon && compact
          ? <Icon name={icon} />
          : null
      }
      <Text fontWeight="bold">
        {value || '-'}
      </Text>
    </Flex>
  )
}

ReportViewSum.defaultProps = {
  icon: undefined,
  compact: false,
}

export const ReportViewRow = ({
  events,
  date,
  sums,
  localizer,
  dayProps,
  compact,
  onEventClick,
}: ReportViewProps) => (
  <ReportViewRowBase compact={compact}>
    <Box>
      {localizer.format(date, 'E d')}
    </Box>
    <Box>
      {
        events
          ? events.map((event) => (
            <SimpleGrid
              gridTemplateColumns={compact ? '1fr' : '1fr 1fr'}
              alignItems="flex-start"
              key={event.uid}
            >
              <CalendarEvent
                event={event}
                variant="outline"
                width="100%"
                hideComment
                onClick={() => onEventClick(event)}
              />
              <Box fontSize=".8em">
                {event.comment}
              </Box>
            </SimpleGrid>
          ))
          : null
      }
    </Box>

    <SimpleGrid
      gridTemplateColumns="repeat(4, 40px)"
      gap={compact ? '2' : '1'}
    >
      <ReportViewSum compact={compact} icon="people" value={sums.people} />
      <ReportViewSum compact={compact} icon="baby" value={sums.babies} />
      <ReportViewSum compact={compact} icon="house" value={sums.house} className={dayProps.houseClassName} />
      <ReportViewSum compact={compact} icon="tent" value={sums.tent} className={dayProps.tentClassName} />
    </SimpleGrid>
  </ReportViewRowBase>
)

ReportViewRow.defaultProps = {
  compact: false,
}

export const ReportViewHeader = () => (
  <ReportViewRowBase>
    <Box>{' '}</Box>
    <Box />
    <SimpleGrid
      gridTemplateColumns="repeat(4, 40px)"
      gap="1"
    >
      <Box textAlign="center"><Icon name="people" /></Box>
      <Box textAlign="center"><Icon name="baby" /></Box>
      <Box textAlign="center"><Icon name="house" /></Box>
      <Box textAlign="center"><Icon name="tent" /></Box>
    </SimpleGrid>
  </ReportViewRowBase>
)
