import {
  throwInvalid,
} from './errors'

/**
 * Transform a document snapshot to a data object with id
 * @param {DocumentReference} snapshot
 * @returns {Object}
 * @todo always use uid
 */
export const docToData = (doc) => {
  if (!doc) throwInvalid('doc is mandatory')
  return ({ uid: doc.id, ...doc.data() })
}

/**
 * Transform a collection snapshot to an array of data objects with id
 * @param {QuerySnapshot} snapshot
 * @returns {Array<Object>}
 */
export const collectionToArray = (snapshot) => {
  if (!snapshot.docs) return []
  return snapshot.docs.map(docToData)
}

/**
 * Transform a collection snapshot to a mapping id:object
 * @param {QuerySnapshot} snapshot
 * @returns {Object<[id]:Object>}
 */
export const fsCollectionToMap = (snapshot) => {
  if (!snapshot?.docs) return []
  return snapshot.docs.reduce(
    (accu, doc) => ({
      ...accu,
      [doc.id]: docToData(doc),
    }),
    {},
  )
}

/**
 * Converts a firestore timestamp to a day without hours
 * @param {Date} date
 * @returns {Date}
 */
// export const dateToDay = (date) => (date ? startOfDay(date) : null)

/**
 * Standard error catcher for Cloud Logging
 *
 * @param {String} name
 * @returns void
 */
export const onError = (name) => (e) => {
  // eslint-disable-next-line no-console
  console.log('DB error', name, e.message, e.status)
}
