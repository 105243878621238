/* eslint-disable import/prefer-default-export */
import firebase from 'firebase/compat/app'
import { ADD_USER, ADD_USERS } from './types'
import db from '../../../init/db'

/**
 *
 * @param {User} payload
 */
export const addUser = (payload) => ({
  type: ADD_USER,
  payload,
})

/**
 *
 * @param {Object} payload   - { placeId, bookings }
 */
export const addUsers = (payload) => ({
  type: ADD_USERS,
  payload,
})

/**
 * Only current user can update theirself
 */
export const updateUser = (values) => async (dispatch) => {
  await db.user.update(values)
  const res = await db.user.get(firebase.auth().currentUser.uid)
  dispatch(addUser(res))
}

export function fetchPlaceUsers({ placeId }) {
  if (!placeId) return null
  return async (dispatch) => {
    const snapshot = await db.user.getCollection().where('places', 'array-contains', placeId).get()

    dispatch(addUsers(
      db.helpers.fsCollectionToMap(snapshot),
    ))
  }
}

export function fetchUsers() {
  return async (dispatch) => {
    const snapshot = await db.user.getCollection().get()

    dispatch(addUsers(
      db.helpers.fsCollectionToMap(snapshot),
    ))
  }
}
