import { useEffect, useState } from 'react'
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom'
import { firstVisibleDay, lastVisibleDay } from 'react-big-calendar/lib/utils/dates'
import { localizer } from './localizer'

const getVisibleRange = (date) => ({
  start: firstVisibleDay(date, localizer),
  end: lastVisibleDay(date, localizer),
})

const useCalendarDate = () => {
  const { url } = useRouteMatch()
  const history = useHistory()
  const { search } = useLocation()
  // init from query param "date"
  const queryDate = (new URLSearchParams(search)).get('date')
  // current date of reference
  const [date, setDate] = useState(queryDate ? new Date(queryDate) : new Date())
  // current date range
  const [range, setRange] = useState(getVisibleRange(date))

  useEffect(() => {
    setRange(getVisibleRange(date))
    // @todo handle query change to use prev/next for navigation
    history.replace({
      pathname: url,
      search: `date=${date.toISOString().split('T')[0]}`,
    })
  }, [date, history, url])

  return {
    date,
    range,
    setDate,
  }
}

export default useCalendarDate
