import React, { useCallback, useContext } from 'react'
import { Link } from 'react-router-dom'
import firebase from 'firebase/compat/app'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
} from '@chakra-ui/react'
import { UserContext } from '../../context/UserProvider'

const Logout = () => {
  const logout = useCallback(() => {
    firebase.auth().signOut().then(
      () => { window.location.href = '/' },
    )
  }, [])

  return (
    <MenuItem onClick={logout}>
      Déconnexion
    </MenuItem>
  )
}

const HeaderMenu = () => {
  const user = useContext(UserContext)
  return (
    <Menu>
      <MenuButton>
        <Avatar
          name={user.firstName}
          src={user?.photoURL}
          size="sm"
        />
      </MenuButton>
      <MenuList>
        <Link to="/profile">
          <MenuItem>
            Profil
          </MenuItem>
        </Link>
        <Logout />
      </MenuList>
    </Menu>
  )
}

export default HeaderMenu
