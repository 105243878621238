import firebase from 'firebase/compat/app'
import { docToData, onError } from './helpers'

export const getCollection = ({ placeId }) => {
  if (!placeId) throw new Error('db.dates.collection : placeId is mandatory')
  return firebase.firestore()
    .collection('places')
    .doc(placeId)
    .collection('dates')
}

export const create = async (values) => {
  const uid = firebase.auth().currentUser?.uid
  const {
    placeId,
    date,
    type = 'house',
    rooms = 1,
    babies = 1,
  } = values

  const inputData = {
    date: firebase.firestore.Timestamp.fromDate(new Date(date)),
    type,
    rooms: Number(rooms),
    babies: Number(babies),
    ownerId: uid,
  }

  return getCollection({ placeId })
    .add(inputData)
    .then((ref) => ref.get())
    .then(docToData)
    .catch(onError('db.date.create'))
}

export const remove = (uid, placeId) => getCollection({ placeId })
  .doc(uid)
  .delete()
  .catch(onError('db.date.delete'))

export const list = async ({ placeId }) => {
  const uid = firebase.auth().currentUser?.uid
  if (!uid) throw new Error('No user uid found')

  return firebase.firestore().collection('places')
    .doc(placeId)
    .collection('dates')
    .get()
    .catch(onError('db.date.list'))
}
