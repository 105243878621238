import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPlace } from '../store/modules/place/actions'
import { getPlace } from '../store/modules/place/selectors'
import { fetchPlaceUsers } from '../store/modules/user/actions'

const usePlace = (placeId, options = {}) => {
  const { withUsers = false } = options
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const place = useSelector((state) => getPlace(state, placeId))

  useEffect(
    () => {
      if (!placeId) return
      setLoading(true)
      if (!place) dispatch(fetchPlace({ placeId }))
      if (withUsers) dispatch(fetchPlaceUsers({ placeId }))
      if (place && place.uid === placeId) setLoading(false)
    },
    [dispatch, place, placeId, withUsers],
  )

  return { place, loading }
}

export default usePlace
