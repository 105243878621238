import PropTypes from 'prop-types'
import React from 'react'
import {
  Box,
  Text,
} from '@chakra-ui/react'

const BaseValue = ({
  label, value, size, color, children,
}) => (
  <Box
    align="center"
    fontSize={size}
    color={value ? color : 'text.200'}
  >
    {children}
    <Text color="text.600" fontSize=".8em">
      {label}
    </Text>
  </Box>
)

BaseValue.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  size: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
}

BaseValue.defaultProps = {
  label: null,
  size: 'md',
  color: 'secondary.500',
  children: null,
  value: '',
}

export default BaseValue
