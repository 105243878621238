import {
  Box, Flex, Heading,
} from '@chakra-ui/react'
import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { LoadingOverlay } from '../../components/Loading'
import { UserContext } from '../../context/UserProvider'
import { getUser } from '../../store/modules/user/selectors'
import { PlaceCode } from '../../components/Place'
import PlaceList from '../../components/Place/PlaceList'

/*
If user has no place, ask for the "passcode", aka secret <-> placeId
Else if user has only one place -> redirect to it
Else Allow to choose a place
*/

export default () => {
  const user = useContext(UserContext)
  const profile = useSelector(
    (state) => getUser(state, user.uid),
  )

  const history = useHistory()
  useEffect(
    () => {
      if (profile?.places?.length === 1) {
        history.push(
          `/place/${profile.places[0]}`,
        )
      }
    },
  )

  if (!profile) return <LoadingOverlay />

  return (
    <Flex alignItems="center" justifyContent="center" textAlign="center">
      <Box>
        <Heading as="h1" size="lg" mb={4}>
          {`Hello ${profile.displayName} !`}
        </Heading>

        {
          profile?.places?.length > 0
            ? <PlaceList mt={4} placeIds={profile?.places} />
            : null
        }

        <PlaceCode
          flexDirection="column"
          padding={8}
          bg="secondary.50"
        />
      </Box>
    </Flex>
  )
}
