/* eslint-disable import/prefer-default-export */
import locales from './locales'

export const TYPES = [
  {
    icon: 'house',
    illustration: 'house',
    value: 'house',
    label: locales.house.room,
    roomLabel: locales.house.rooms,
  },
  {
    icon: 'tent',
    illustration: 'tentvan',
    value: 'tent',
    label: locales.tent.room,
    roomLabel: locales.tent.rooms,
  },
]

export const getTypeDefinition = (type) => TYPES.find(
  ({ value }) => value === type,
)

export const LOAD_STATUS_OVERLOAD = 5
export const LOAD_STATUS_FULL = 4
export const LOAD_STATUS_HIGH = 3
export const LOAD_STATUS_MEDIUM = 2
export const LOAD_STATUS_LOW = 1
export const LOAD_STATUS_EMPTY = 0

export const LOAD_STATUSES = [
  LOAD_STATUS_OVERLOAD,
  LOAD_STATUS_FULL,
  LOAD_STATUS_HIGH,
  LOAD_STATUS_MEDIUM,
  LOAD_STATUS_LOW,
  LOAD_STATUS_EMPTY,
]
