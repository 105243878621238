import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getDateId } from '../../helpers/date'
import { LOAD_STATUS_EMPTY, loadByDate } from '../../store/modules/booking/helpers'
import { getRawBookings } from '../../store/modules/booking/selectors'

const getDayProps = (loads, day) => {
  const dayId = getDateId(day)
  return {
    className: `day-status day-status-${loads[dayId]?.status?.global || LOAD_STATUS_EMPTY}`,
    houseClassName: `day-status day-status-${loads[dayId]?.status?.house || LOAD_STATUS_EMPTY}`,
    tentClassName: `day-status day-status-${loads[dayId]?.status?.tent || LOAD_STATUS_EMPTY}`,
  }
}

const getDayPropsGetter = (loads, { start, end }) => {
  const current = new Date(start)
  const result = {}
  if (!(loads && Object.keys(loads).length)) return () => ({})
  while (current <= end) {
    result[getDateId(current)] = getDayProps(loads, current)
    current.setDate(current.getDate() + 1)
  }
  return (day) => result[getDateId(day)] || {}
}

/**
 * Provides helpers to be used by the calendar to retrieve
 * elements properties
 *
 * @param {Place} place
 * @param {{ start, end }} range  - The current date range
 */
const usePropGetters = (place, range) => {
  const bookings = useSelector(
    (state) => getRawBookings(state, place?.uid),
  )

  const loads = loadByDate({ place, bookings, range })

  const dayPropGetter = useMemo(() => getDayPropsGetter(loads, range), [loads, range])

  return {
    dayPropGetter,
  }
}

export default usePropGetters
