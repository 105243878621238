import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFromPath } from '../store/modules/resource/actions'
import { getResource } from '../store/modules/resource/selectors'

const useResource = (path) => {
  const dispatch = useDispatch()
  const resource = useSelector((state) => (
    getResource(state, path)
  ))
  useEffect(
    () => path && dispatch(setFromPath({ path })),
    [dispatch, path],
  )

  return { resource }
}

export default useResource
