/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react'
import { Switch, Route } from 'react-router-dom'

import { UserContext } from '../context/UserProvider'

import SignIn from '../pages/SignIn'
import PasswordReset from '../pages/PasswordReset'

import Home from '../pages/Home'
import Place from '../pages/Place'
import Profile from '../pages/Profile'
import Register from '../pages/Register'

const PublicRoutes = () => (
  <Switch>
    <Route path="/passwordReset">
      <PasswordReset />
    </Route>
    <Route path="/">
      <SignIn />
    </Route>
  </Switch>
)

const AuthenticatedRoutes = () => (
  <Switch>
    <Route path="/place/:placeId" render={(props) => <Place {...props} />} />
    <Route path="/profile">
      <Profile />
    </Route>
    <Route path="/register">
      <Register />
    </Route>
    <Route exact path="/">
      <Home />
    </Route>
    {/* <Route path="/members" render={(props) => <Members {...props} />} /> */}
  </Switch>
)

export default () => {
  const user = useContext(UserContext)
  return user
    ? <AuthenticatedRoutes />
    : <PublicRoutes />
}
