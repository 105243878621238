import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'react-big-calendar/lib/utils/constants'
import { add, sub } from 'date-fns'
import {
  Button, ButtonGroup, Flex, SimpleGrid,
  Box,
} from '@chakra-ui/react'
import CalendarToolbarViews from './Calendar.ToolbarViews'
import Icon from '../Icon'
import { DateType } from '../../types'
import { format } from '../../helpers/date'

/**
 * Calendar toolbar, see calendar documentation
 */
const CalendarToolbar = ({
  date,
  view,
  views,
  label,
  localizer,
  onNavigate,
  onView,
  onEventAdd,
  compact,
}) => {
  const { messages } = localizer

  return (
    <SimpleGrid
      gridTemplateColumns="1fr 1fr 1fr"
      gridGap={4}
      marginY={4}
      textAlign="center"
    >
      <ButtonGroup colorScheme="secondary" size="sm" gridGap={2}>
        {
          compact
            ? null
            : (
              <Button
                onClick={() => onNavigate(navigate.TODAY)}
                title={messages.todayLabel}
              >
                <Icon name={messages.today} variant="outline" />
              </Button>
            )
        }

        <CalendarToolbarViews
          messages={messages}
          view={view}
          views={views}
          onView={onView}
        />

        <Button
          colorScheme="secondary"
          onClick={onEventAdd}
        >
          <Icon name="plus" marginRight={compact ? 0 : 1} />
          {compact ? null : 'Ajouter'}
        </Button>
      </ButtonGroup>

      <Box
        alignSelf="center"
        fontWeight="bold"
      >
        {label}
      </Box>

      <Flex justify="flex-end">
        <ButtonGroup
          colorScheme="secondary"
          size="sm"
          isAttached
        >
          <Button onClick={() => onNavigate(navigate.PREVIOUS)}>
            <Icon name="angle-small-left" />
            {compact ? '' : format(sub(date, { months: 1 }), 'MMMM')}
          </Button>
          <Button onClick={() => onNavigate(navigate.NEXT)}>
            {compact ? '' : format(add(date, { months: 1 }), 'MMMM')}
            <Icon name="angle-small-right" />
          </Button>
        </ButtonGroup>
      </Flex>

    </SimpleGrid>
  )
}

CalendarToolbar.propTypes = {
  date: DateType.isRequired,
  view: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  localizer: PropTypes.object.isRequired,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onEventAdd: PropTypes.func.isRequired,
  compact: PropTypes.bool,
  // children: PropTypes.element,
}

CalendarToolbar.defaultProps = {
  compact: false,
}

export default CalendarToolbar
