/* eslint-disable import/prefer-default-export */
import firebase from 'firebase/compat/app'
import 'firebase/compat/analytics'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/functions'
import 'firebase/compat/app-check'

import initAuth from './auth'

const firebaseConfig = {
  apiKey: 'AIzaSyA6SUgmS2Qkv3FdvqVRxMDWHjigyagf0kU',
  authDomain: 'sauvagenda.fr',
  projectId: 'sauvagenda-21svg',
  storageBucket: 'sauvagenda-21svg.appspot.com',
  messagingSenderId: '333601282474',
  appId: '1:333601282474:web:101396b581658536fe73d3',
  databaseURL: 'https://sauvagenda-21svg-default-rtdb.europe-west1.firebasedatabase.app',
  measurementId: 'G-M1X15VG0BQ',
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

initAuth()

const db = firebase.firestore()

/*
// Not working yet, add this in index.html DOR DEV ONLY
// <script>
//   self.FIREBASE_APPCHECK_DEBUG_TOKEN='<YOUR DEBUG TOKEN>'
// </script>
*/

// Not available for web yet
// https://firebase.google.com/docs/app-check/web/recaptcha-provider#enable-other
// const appCheck = firebase.appCheck()
// appCheck.activate('6Ld8XFEfAAAAAD9P-qggwKTMfVImh4MXLzsoV3rc', true)

/**
 * Enable emulators
 */
if (process.env.REACT_APP_FIREBASE_FIRESTORE_EMULATOR === '1') {
  // eslint-disable-next-line no-console
  console.warn('🙈 --- running firestor emulator')
  db.useEmulator('localhost', 8080)
  firebase.functions().useFunctionsEmulator('http://localhost:5000')
}
