/* eslint-disable max-classes-per-file */
/* eslint-disable import/prefer-default-export */

class ErrorPermission extends Error {
  constructor(...args) {
    super(...args)
    this.name = 'Denied'
    this.code = 401
    Error.captureStackTrace(this, ErrorPermission)
  }
}

class ErrorNotFound extends Error {
  constructor(...args) {
    super(...args)
    this.name = 'NotFound'
    this.code = 404
    Error.captureStackTrace(this, ErrorNotFound)
  }
}

class ErrorInvalid extends Error {
  constructor(...args) {
    super(...args)
    this.name = 'Invalid'
    this.code = 422
    Error.captureStackTrace(this, ErrorInvalid)
  }
}

class ErrorRefused extends Error {
  constructor(...args) {
    super(...args)
    this.name = 'Refused'
    this.code = 403
    Error.captureStackTrace(this, ErrorRefused)
  }
}

class ErrorUnsupported extends Error {
  constructor(...args) {
    super(...args)
    this.name = 'Unsupported'
    this.code = 400
    Error.captureStackTrace(this, ErrorUnsupported)
  }
}

class ErrorInternal extends Error {
  constructor(...args) {
    super(...args)
    this.name = 'Internal'
    this.code = 500
    Error.captureStackTrace(this, ErrorInternal)
  }
}

class ErrorTimeout extends Error {
  constructor(...args) {
    super(...args)
    this.name = 'Timeout'
    this.code = 408
    Error.captureStackTrace(this, ErrorTimeout)
  }
}

export const throwNotFound = (message) => {
  throw new ErrorNotFound(message)
}

export const throwInvalid = (message) => {
  throw new ErrorInvalid(message)
}

export const log = {
  error: (e) => {
    if (process.env.NODE_ENV === 'development') {
      throw e
    } else {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  },
}

/**
 * Firebase error handling
 * @see https://firebase.google.com/docs/reference/node/firebase.firestore#firestoreerrorcode
 */
const FIRESTORE_ERROR_CODES = {
  'permission-denied': ErrorPermission,
  unauthenticated: ErrorPermission,
  'invalid-argument': ErrorInvalid,
  'deadline-exceeded': ErrorTimeout,
  'not-found': ErrorNotFound,
  'already-exists': ErrorRefused,
  'failed-precondition': ErrorRefused,
  'out-of-range': ErrorRefused,
  unimplemented: ErrorUnsupported,
  'resource-exhausted': Error,
  'data-loss': Error,
  aborted: Error,
  internal: ErrorInternal,
  unknown: Error,
  unavailable: Error,
  cancelled: Error,
}

export const onFirestoreError = (e, message) => {
  const ErrorClass = FIRESTORE_ERROR_CODES[e.code] || Error
  const msg = `Firestore error::${e.code} ${message || e.message}`
  log.error(new ErrorClass(msg))
}
