/* eslint-disable no-restricted-globals */
import React from 'react'
import PropTypes from 'prop-types'

import {
  Alert,
  Box, Button, ButtonGroup, SimpleGrid,
} from '@chakra-ui/react'
import firebase from 'firebase/compat/app'
import Values from '../components/Booking.Values'
import { Booking } from '../../../types'
import { getTypeDefinition } from '../../../constants/booking'
import db from '../../../init/db'
import Icon from '../../Icon'

const BookingShow = ({
  placeId,
  values,
  // onCancel,
  onSuccess,
  onEdit,
}) => {
  const { roomLabel = 'Place(s)' } = getTypeDefinition(values.type) || {}

  const handleDelete = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (!values.uid) return null
    // eslint-disable-next-line no-alert
    if (!confirm('Voulez vous supprimer cette réservation ?')) return null
    const res = db.booking.remove(values.uid, placeId)
    if (onSuccess) onSuccess(res)
    return res
  }

  return (
    <SimpleGrid
      direction="column"
      height="540px"
      gridTemplateRows="1fr auto"
      gridGap="8"
      alignItems="center"
      justifyContent="center"
    >
      <Box maxWidth="400px">
        <Values.Type variant="illustration" size="2em" iconSize="3em" value={values.type} />
        <Values.Period size="xl" value={[values.start, values.end]} />
        <SimpleGrid columns={values.babies ? 3 : 2} my="8">
          <Values.Number size="xl" value={values.rooms} label={roomLabel} />
          <Values.Number size="xl" value={values.people} label="> 3ans" />
          {
            values.babies ? (
              <Values.Number size="xl" value={values.babies} label="Bébé(s)" />
            ) : null
          }
        </SimpleGrid>
        {
          values.comment ? (
            <Alert
              status="info"
              fontSize="sm"
              bg="primary.50"
              whiteSpace="pre-line"
            >
              <Icon name="chat" marginRight={2} />
              {values.comment}
            </Alert>
          ) : null
        }
      </Box>

      {
        firebase.auth().currentUser.uid === values.ownerId ? (
          <Box py={4} textAlign="center">
            <ButtonGroup spacing={2}>
              <Button colorScheme="danger" onClick={handleDelete}>
                Supprimer
              </Button>
              <Button onClick={onEdit}>
                Modifier
              </Button>
            </ButtonGroup>
          </Box>
        ) : null
      }
    </SimpleGrid>
  )
}

BookingShow.propTypes = {
  placeId: PropTypes.string.isRequired,
  values: Booking.isRequired,
  // onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  onEdit: PropTypes.func.isRequired,
}

BookingShow.defaultProps = {
  // onCancel: null,
  onSuccess: null,
}

export default BookingShow
