import { omit } from 'ramda'

// import { filterByRange } from './helpers'
import { ADD_BOOKINGS, REMOVE_BOOKINGS } from './types'

const initialState = {}

/**
 * Preformat at storage time
 * @param {Booking} item
 */
const beforeAdd = (item) => ({
  ...item,
  start: item.start?.toDate(),
  end: item.end?.toDate(),
})

const addBookingsToPlace = (current, items) => items.reduce(
  (accu, item) => ({ ...accu, [item.uid]: beforeAdd(item) }),
  current,
)

/**
 * @deprecated ? Currently not relevant because we can not filter by date range
 const removeBookingsFromPlace = (current, itemIds) => omit(itemIds, current)

 const diffOnRange = (state, { placeId, bookings, range }) => {
   if (!(range?.start && range.end)) throw new Error('range is mandatory')
   const currentIds = pluck('uid', filterByRange(range)(Object.values(state[placeId] || {})))

   return {
     add: bookings,
     // all current ids not contained in the new bookings ids
     remove: difference(currentIds, pluck('uid', bookings)),
   }
 }
 */

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_BOOKINGS: {
      const { placeId, bookings } = action.payload
      return {
        ...state,
        [placeId]: addBookingsToPlace(state[placeId], bookings),
      }
    }
    case REMOVE_BOOKINGS: {
      const { placeId, bookingIds } = action.payload
      return {
        // ...state,
        [placeId]: omit(bookingIds, state[placeId]),
      }
    }
    /** @deprecated ? Currently not relevant because we can not filter by date range */
    // case REPLACE_BOOKINGS: {
    //   const { placeId, bookings, range } = action.payload
    //   const { add, remove } = diffOnRange(state, { placeId, bookings, range })

    //   // This is the ADD_BOOKINGS reducer mixed with the REMOVE_BOOKINGS one
    //   return {
    //     ...state,
    //     [placeId]: addBookingsToPlace(
    //       removeBookingsFromPlace(
    //         state[placeId],
    //         remove,
    //       ),
    //       add,
    //     ),
    //   }
    // }
    default:
      return state
  }
}
