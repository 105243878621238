import { useState } from 'react'
import store from './index'

/**
 * Provide
 * - a handler to call the action
 * - A local state to keep the loading state
 * - Requires an async action
 *
 * @param {*} param0
 */
const useAsyncAction = () => {
  const [loading, setLoading] = useState(false)

  const dispatch = async (action) => {
    setLoading(true)
    await store.dispatch(action)
    setLoading(false)
  }

  return [dispatch, loading]
}

export default useAsyncAction
