import React from 'react'
import firebase from 'firebase/compat/app'
import { Box } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { updateUser } from '../../store/modules/user/actions'
import ProfileHeader from './ProfileHeader'
import ProfileForm from './ProfileForm'
import { getUser } from '../../store/modules/user/selectors'
import { LoadingOverlay, LoadingWrapper } from '../../components/Loading'
import useAsyncAction from '../../store/useAsyncAction'

export default () => {
  const [dispatch, loading] = useAsyncAction()
  const user = firebase.auth().currentUser
  const {
    photoURL, email, uid,
  } = user

  const userProfile = useSelector(
    (state) => getUser(state, uid),
  )

  return (
    <Box>
      <ProfileHeader
        uid={uid}
        picture={photoURL}
        email={email}
        name={userProfile?.displayName}
      />
      {
        userProfile
          ? (
            <LoadingWrapper loading={loading}>
              <ProfileForm
                onSubmit={(data) => dispatch(updateUser(data))}
                userProfile={userProfile}
              />
            </LoadingWrapper>
          )
          : <LoadingOverlay />
      }
    </Box>
  )
}
