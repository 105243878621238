/**
 * @see https://coolors.co/a3c8fe-456fc3-6bdddd-f4a4a4-ffea92
 * previously https://coolors.co/ffa69e-eace71-b8f2e6-aed9e0-5e6472
 * @see https://smart-swatch.netlify.app/ for palette generation per color
 */

const COLORS = {

  darken: {
    50: 'rgba(0,0,0, .05)',
    100: 'rgba(0,0,0, .1)',
    200: 'rgba(0,0,0, .2)',
    300: 'rgba(0,0,0, .3)',
    400: 'rgba(0,0,0, .4)',
    500: 'rgba(0,0,0, .5)',
    600: 'rgba(0,0,0, .6)',
    700: 'rgba(0,0,0, .7)',
    800: 'rgba(0,0,0, .8)',
    900: 'rgba(0,0,0, .9)',
  },

  turquoise: {
    50: '#defdfd',
    100: '#bbf2f2',
    200: '#95e7e7',
    300: '#6edede',
    400: '#49d4d4',
    500: '#31bbbb',
    600: '#219191',
    700: '#136868',
    800: '#003f3f',
    900: '#001717',
  },

  hanblue: {
    50: '#e7edff',
    100: '#c2cdf1',
    200: '#9dafe3',
    300: '#7794d4',
    400: '#5179c7',
    500: '#3857ae',
    600: '#2b3e88',
    700: '#1c2762',
    800: '#0e133e',
    900: '#00021b',
  },

  pastelpink: {
    50: '#ffe6e7',
    100: '#f7bbbb',
    200: '#ef8e94',
    300: '#e96273',
    400: '#e33555',
    500: '#ca1c46',
    600: '#9d153f',
    700: '#710f35',
    800: '#440824',
    900: '#1b010f',
  },

  apricot: {
    50: '#fff2dd',
    100: '#ffdab0',
    200: '#ffc180',
    300: '#fea84e',
    400: '#fe911f',
    500: '#e57707',
    600: '#b35c03',
    700: '#804200',
    800: '#4e2600',
    900: '#1e0b00',
  },

  yellowcrayola: {
    50: '#fffadd',
    100: '#fff0b0',
    200: '#ffe680',
    300: '#ffdc4f',
    400: '#ffd222',
    500: '#e6b80d',
    600: '#b38f05',
    700: '#806600',
    800: '#4d3d00',
    900: '#1c1400',
  },

  grannysmith: {
    50: '#ecfde4',
    100: '#cbf7bb',
    200: '#b0f18f',
    300: '#9aeb64',
    400: '#8ae539',
    500: '#7bcc23',
    600: '#699f1a',
    700: '#517212',
    800: '#344408',
    900: '#111800',
  },

  babyblueeyes: {
    50: '#e3f1ff',
    100: '#b3d2fe',
    200: '#84bcfb',
    300: '#54abf9',
    400: '#2ca0f8',
    500: '#1b92de',
    600: '#127dae',
    700: '#08627c',
    800: '#01424c',
    900: '#00191d',
  },

  blackcoral: { // gray
    50: '#e9f3fe',
    100: '#d3d7e1',
    200: '#b8bdc8',
    300: '#9ca2af',
    400: '#818897',
    500: '#686e7e', // default
    600: '#505663',
    700: '#393d48',
    800: '#21252e',
    900: '#0b0b17',
  },

  gainsboro: { // lightGray
    50: '#e9f6f6',
    100: '#d3dede',
    200: '#b9c8c8',
    300: '#9eb2b2',
    400: '#829c9b',
    500: '#698383',
    600: '#516565',
    700: '#394848',
    800: '#212c2c',
    900: '#031111',
  },

  /** PREVIOUS COLORS */

  melon: {
    50: '#ffe5e2',
    100: '#ffb9b3', // default
    200: '#fd8381',
    300: '#fc4f58',
    400: '#fc1e36',
    500: '#e30629',
    600: '#b1032a',
    700: '#7f0227',
    800: '#4d001e',
    900: '#1e0010',
  },
  eggshell: {
    50: '#fcf7e9', // default
    100: '#f4e4c2',
    200: '#edcd98',
    300: '#e6b06e',
    400: '#e08f46',
    500: '#c66d2f',
    600: '#9b4d25',
    700: '#6e311b',
    800: '#421a10',
    900: '#160703',
  },
  aeroblue: {
    50: '#e3fdf7',
    100: '#bef3e8', // default
    200: '#97ead9',
    300: '#70e2ca',
    400: '#4ddabc',
    500: '#38c1a2',
    600: '#29967e',
    700: '#1c6c5a',
    800: '#0c4136',
    900: '#001712',
  },
  powderblue: {
    50: '#e3f7fd',
    100: '#c4e4eb',
    200: '#a3d4dc', // default
    300: '#80bdcd',
    400: '#5fa4be',
    500: '#4785a5',
    600: '#346180',
    700: '#23415c',
    800: '#102338',
    900: '#000915',
  },

  manatee: { // light gray
    50: '#eaf1fc',
    100: '#d2d7e0',
    200: '#b8bdc6',
    300: '#9da3ae',
    400: '#828896',
    500: '#696c7d',
    600: '#515362',
    700: '#393a47',
    800: '#22222d',
    900: '#0f0b16',
  },

  snow: {
    50: '#ffffff',
    100: '#fafafa',
    200: '#f5f5f5',
    300: '#eaeaea',
    400: '#e5e5e5',
    500: '#e0e0e0',
    600: '#dbdbdb',
    700: '#d6d6d6',
    800: '#d1d1d1',
    900: '#cccccc',
  },
}

export default COLORS
