import React from 'react'
import {
  Avatar,
  Box,
  Heading,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import PropTypes from 'prop-types'

const ProfileHeader = ({
  picture, name, email, uid,
}) => (
  <SimpleGrid gridTemplateColumns="auto 1fr" gridGap={4} my={8}>
    <Avatar
      name={name}
      src={picture}
      size="lg"
      alt={uid}
    />
    <Box>
      <Heading as="h1" size="lg">{name}</Heading>
      <Text color="primary.500">{email}</Text>
    </Box>
  </SimpleGrid>
)

ProfileHeader.propTypes = {
  uid: PropTypes.string,
  picture: PropTypes.string,
  email: PropTypes.string.isRequired,
  name: PropTypes.string,
}

ProfileHeader.defaultProps = {
  uid: 'unknown',
  picture: null,
  name: null,
}

export default ProfileHeader
