/* eslint-disable no-underscore-dangle */
import { fr } from 'date-fns/locale'

const defaultLang = 'fr'
const locales = { fr }

export const setLang = (lng = defaultLang) => {
  localStorage.setItem('lng', lng)
}

export const getLang = () => localStorage.getItem('lng')

export const getLocales = (lang) => {
  const lng = lang || getLang()
  return locales[lng]
}
