import React from 'react'
import { createIconSet } from './helpers'

export const calendarTable = createIconSet(
  'calendar-table',
  <g>
    <path d="M38.0002 4H36.0002V2C36.0002 1.46957 35.7895 0.960859 35.4144 0.585786C35.0393 0.210714 34.5306 0 34.0002 0C33.4697 0 32.961 0.210714 32.586 0.585786C32.2109 0.960859 32.0002 1.46957 32.0002 2V4H16.0002V2C16.0002 1.46957 15.7895 0.960859 15.4144 0.585786C15.0393 0.210714 14.5306 0 14.0002 0C13.4698 0 12.961 0.210714 12.586 0.585786C12.2109 0.960859 12.0002 1.46957 12.0002 2V4H10.0002C7.34899 4.00318 4.8073 5.05776 2.93262 6.93244C1.05795 8.80712 0.00335882 11.3488 0.000183105 14L0.000183105 38C0.00335882 40.6512 1.05795 43.1929 2.93262 45.0676C4.8073 46.9422 7.34899 47.9968 10.0002 48H38.0002C40.6514 47.9968 43.1931 46.9422 45.0677 45.0676C46.9424 43.1929 47.997 40.6512 48.0002 38V14C47.997 11.3488 46.9424 8.80712 45.0677 6.93244C43.1931 5.05776 40.6514 4.00318 38.0002 4ZM38.0002 44H10.0002C8.40888 44 6.88276 43.3679 5.75754 42.2426C4.63232 41.1174 4.00018 39.5913 4.00018 38V20H44.0002V38C44.0002 39.5913 43.368 41.1174 42.2428 42.2426C41.1176 43.3679 39.5915 44 38.0002 44Z" />
    <path d="M11.0002 30C12.657 30 14.0002 28.6569 14.0002 27C14.0002 25.3431 12.657 24 11.0002 24C9.34333 24 8.00018 25.3431 8.00018 27C8.00018 28.6569 9.34333 30 11.0002 30Z" />
    <path d="M11.0002 39C12.657 39 14.0002 37.6569 14.0002 36C14.0002 34.3431 12.657 33 11.0002 33C9.34333 33 8.00018 34.3431 8.00018 36C8.00018 37.6569 9.34333 39 11.0002 39Z" />
    <path d="M20.0002 30C21.657 30 23.0002 28.6569 23.0002 27C23.0002 25.3431 21.657 24 20.0002 24C18.3433 24 17.0002 25.3431 17.0002 27C17.0002 28.6569 18.3433 30 20.0002 30Z" />
    <path d="M20.0002 39C21.657 39 23.0002 37.6569 23.0002 36C23.0002 34.3431 21.657 33 20.0002 33C18.3433 33 17.0002 34.3431 17.0002 36C17.0002 37.6569 18.3433 39 20.0002 39Z" />
    <path d="M29.0002 30C30.657 30 32.0002 28.6569 32.0002 27C32.0002 25.3431 30.657 24 29.0002 24C27.3433 24 26.0002 25.3431 26.0002 27C26.0002 28.6569 27.3433 30 29.0002 30Z" />
    <path d="M29.0002 39C30.657 39 32.0002 37.6569 32.0002 36C32.0002 34.3431 30.657 33 29.0002 33C27.3433 33 26.0002 34.3431 26.0002 36C26.0002 37.6569 27.3433 39 29.0002 39Z" />
    <path d="M38.0002 30C39.657 30 41.0002 28.6569 41.0002 27C41.0002 25.3431 39.657 24 38.0002 24C36.3433 24 35.0002 25.3431 35.0002 27C35.0002 28.6569 36.3433 30 38.0002 30Z" />
    <path d="M38.0002 39C39.657 39 41.0002 37.6569 41.0002 36C41.0002 34.3431 39.657 33 38.0002 33C36.3433 33 35.0002 34.3431 35.0002 36C35.0002 37.6569 36.3433 39 38.0002 39Z" />
  </g>,
  <g>
    <path d="M38 4H36V2C36 1.46957 35.7893 0.960859 35.4142 0.585786C35.0391 0.210714 34.5304 0 34 0C33.4696 0 32.9609 0.210714 32.5858 0.585786C32.2107 0.960859 32 1.46957 32 2V4H16V2C16 1.46957 15.7893 0.960859 15.4142 0.585786C15.0391 0.210714 14.5304 0 14 0C13.4696 0 12.9609 0.210714 12.5858 0.585786C12.2107 0.960859 12 1.46957 12 2V4H10C7.34881 4.00318 4.80712 5.05776 2.93244 6.93244C1.05776 8.80712 0.00317572 11.3488 0 14L0 38C0.00317572 40.6512 1.05776 43.1929 2.93244 45.0676C4.80712 46.9422 7.34881 47.9968 10 48H38C40.6512 47.9968 43.1929 46.9422 45.0676 45.0676C46.9422 43.1929 47.9968 40.6512 48 38V14C47.9968 11.3488 46.9422 8.80712 45.0676 6.93244C43.1929 5.05776 40.6512 4.00318 38 4ZM4 14C4 12.4087 4.63214 10.8826 5.75736 9.75736C6.88258 8.63214 8.4087 8 10 8H38C39.5913 8 41.1174 8.63214 42.2426 9.75736C43.3679 10.8826 44 12.4087 44 14V16H4V14ZM38 44H10C8.4087 44 6.88258 43.3679 5.75736 42.2426C4.63214 41.1174 4 39.5913 4 38V20H44V38C44 39.5913 43.3679 41.1174 42.2426 42.2426C41.1174 43.3679 39.5913 44 38 44Z" />
    <path d="M11 29C12.6569 29 14 27.6569 14 26C14 24.3431 12.6569 23 11 23C9.34315 23 8 24.3431 8 26C8 27.6569 9.34315 29 11 29Z" />
    <path d="M11 38C12.6569 38 14 36.6569 14 35C14 33.3431 12.6569 32 11 32C9.34315 32 8 33.3431 8 35C8 36.6569 9.34315 38 11 38Z" />
    <path d="M20 29C21.6569 29 23 27.6569 23 26C23 24.3431 21.6569 23 20 23C18.3431 23 17 24.3431 17 26C17 27.6569 18.3431 29 20 29Z" />
    <path d="M20 38C21.6569 38 23 36.6569 23 35C23 33.3431 21.6569 32 20 32C18.3431 32 17 33.3431 17 35C17 36.6569 18.3431 38 20 38Z" />
    <path d="M29 29C30.6569 29 32 27.6569 32 26C32 24.3431 30.6569 23 29 23C27.3431 23 26 24.3431 26 26C26 27.6569 27.3431 29 29 29Z" />
    <path d="M29 38C30.6569 38 32 36.6569 32 35C32 33.3431 30.6569 32 29 32C27.3431 32 26 33.3431 26 35C26 36.6569 27.3431 38 29 38Z" />
    <path d="M38 29C39.6569 29 41 27.6569 41 26C41 24.3431 39.6569 23 38 23C36.3431 23 35 24.3431 35 26C35 27.6569 36.3431 29 38 29Z" />
    <path d="M38 38C39.6569 38 41 36.6569 41 35C41 33.3431 39.6569 32 38 32C36.3431 32 35 33.3431 35 35C35 36.6569 36.3431 38 38 38Z" />
  </g>,
)

export const calendarDay = createIconSet(
  'calendar-day',
  <g>
    <path d="M38.0002 4H36.0002V2C36.0002 1.46957 35.7895 0.960859 35.4144 0.585786C35.0393 0.210714 34.5306 0 34.0002 0C33.4697 0 32.961 0.210714 32.586 0.585786C32.2109 0.960859 32.0002 1.46957 32.0002 2V4H16.0002V2C16.0002 1.46957 15.7895 0.960859 15.4144 0.585786C15.0393 0.210714 14.5306 0 14.0002 0C13.4698 0 12.961 0.210714 12.586 0.585786C12.2109 0.960859 12.0002 1.46957 12.0002 2V4H10.0002C7.34899 4.00318 4.8073 5.05776 2.93262 6.93244C1.05795 8.80712 0.00335882 11.3488 0.000183105 14L0.000183105 38C0.00335882 40.6512 1.05795 43.1929 2.93262 45.0676C4.8073 46.9422 7.34899 47.9968 10.0002 48H38.0002C40.6514 47.9968 43.1931 46.9422 45.0677 45.0676C46.9424 43.1929 47.997 40.6512 48.0002 38V14C47.997 11.3488 46.9424 8.80712 45.0677 6.93244C43.1931 5.05776 40.6514 4.00318 38.0002 4ZM38.0002 44H10.0002C8.40888 44 6.88276 43.3679 5.75754 42.2426C4.63232 41.1174 4.00018 39.5913 4.00018 38V20H44.0002V38C44.0002 39.5913 43.368 41.1174 42.2428 42.2426C41.1176 43.3679 39.5915 44 38.0002 44Z" />
    <path d="M34.0002 34C36.7616 34 39.0002 31.7614 39.0002 29C39.0002 26.2386 36.7616 24 34.0002 24C31.2388 24 29.0002 26.2386 29.0002 29C29.0002 31.7614 31.2388 34 34.0002 34Z" />
  </g>,
  <g>
    <path d="M38 4H36V2C36 1.46957 35.7893 0.960859 35.4142 0.585786C35.0391 0.210714 34.5304 0 34 0C33.4696 0 32.9609 0.210714 32.5858 0.585786C32.2107 0.960859 32 1.46957 32 2V4H16V2C16 1.46957 15.7893 0.960859 15.4142 0.585786C15.0391 0.210714 14.5304 0 14 0C13.4696 0 12.9609 0.210714 12.5858 0.585786C12.2107 0.960859 12 1.46957 12 2V4H10C7.34881 4.00318 4.80712 5.05776 2.93244 6.93244C1.05776 8.80712 0.00317572 11.3488 0 14L0 38C0.00317572 40.6512 1.05776 43.1929 2.93244 45.0676C4.80712 46.9422 7.34881 47.9968 10 48H38C40.6512 47.9968 43.1929 46.9422 45.0676 45.0676C46.9422 43.1929 47.9968 40.6512 48 38V14C47.9968 11.3488 46.9422 8.80712 45.0676 6.93244C43.1929 5.05776 40.6512 4.00318 38 4ZM4 14C4 12.4087 4.63214 10.8826 5.75736 9.75736C6.88258 8.63214 8.4087 8 10 8H38C39.5913 8 41.1174 8.63214 42.2426 9.75736C43.3679 10.8826 44 12.4087 44 14V16H4V14ZM38 44H10C8.4087 44 6.88258 43.3679 5.75736 42.2426C4.63214 41.1174 4 39.5913 4 38V20H44V38C44 39.5913 43.3679 41.1174 42.2426 42.2426C41.1174 43.3679 39.5913 44 38 44Z" />
    <path d="M34 35C36.7614 35 39 32.7614 39 30C39 27.2386 36.7614 25 34 25C31.2386 25 29 27.2386 29 30C29 32.7614 31.2386 35 34 35Z" />
  </g>,
)

export const calendarMoon = createIconSet(
  'calendar-moon',
  <g>
    <path fillRule="evenodd" clipRule="evenodd" d="M36.0002 4H38.0002C40.6514 4.00318 43.1931 5.05776 45.0677 6.93244C46.9424 8.80712 47.997 11.3488 48.0002 14V38C47.997 40.6512 46.9424 43.1929 45.0677 45.0676C43.1931 46.9422 40.6514 47.9968 38.0002 48H10.0002C7.34899 47.9968 4.8073 46.9422 2.93262 45.0676C1.05795 43.1929 0.00335882 40.6512 0.000183105 38V14C0.00335882 11.3488 1.05795 8.80712 2.93262 6.93244C4.8073 5.05776 7.34899 4.00318 10.0002 4H12.0002V2C12.0002 1.46957 12.2109 0.960859 12.586 0.585786C12.961 0.210714 13.4698 0 14.0002 0C14.5306 0 15.0393 0.210714 15.4144 0.585786C15.7895 0.960859 16.0002 1.46957 16.0002 2V4H32.0002V2C32.0002 1.46957 32.2109 0.960859 32.586 0.585786C32.961 0.210714 33.4697 0 34.0002 0C34.5306 0 35.0393 0.210714 35.4144 0.585786C35.7895 0.960859 36.0002 1.46957 36.0002 2V4ZM34.3461 32.1262C34.7952 32.1106 35.2444 32.095 35.6935 32.0013C35.9181 31.9564 36.0753 32.1811 35.963 32.3608C35.6486 32.8775 35.3118 33.282 34.93 33.7313C32.7517 36.3599 29.4954 38 25.8799 38C19.3225 38 14.0002 32.6079 14.0002 25.9353C14.0002 20.5432 17.4361 15.96 22.3092 14.4098C23.0952 14.1626 23.6566 14.0503 24.0384 14.0054C24.263 13.9604 24.3977 14.2076 24.2854 14.3873C24.016 14.8142 23.6342 15.4432 23.4321 15.8476C22.7135 17.3529 22.3317 19.0379 22.3317 20.7679C22.3317 23.8009 23.4994 26.6542 25.6104 28.811C27.7213 30.9679 30.5509 32.1586 33.5377 32.1586C33.6275 32.1586 33.7173 32.153 33.8072 32.1474C33.897 32.1418 33.9868 32.1361 34.0766 32.1361C34.1665 32.1324 34.2563 32.1293 34.3461 32.1262Z" />
  </g>,
  <g>
    <path d="M36 4H38C40.6512 4.00318 43.1929 5.05776 45.0676 6.93244C46.9422 8.80712 47.9968 11.3488 48 14V38C47.9968 40.6512 46.9422 43.1929 45.0676 45.0676C43.1929 46.9422 40.6512 47.9968 38 48H10C7.34881 47.9968 4.80712 46.9422 2.93244 45.0676C1.05776 43.1929 0.00317572 40.6512 0 38V14C0.00317572 11.3488 1.05776 8.80712 2.93244 6.93244C4.80712 5.05776 7.34881 4.00318 10 4H12V2C12 1.46957 12.2107 0.960859 12.5858 0.585786C12.9609 0.210714 13.4696 0 14 0C14.5304 0 15.0391 0.210714 15.4142 0.585786C15.7893 0.960859 16 1.46957 16 2V4H32V2C32 1.46957 32.2107 0.960859 32.5858 0.585786C32.9609 0.210714 33.4696 0 34 0C34.5304 0 35.0391 0.210714 35.4142 0.585786C35.7893 0.960859 36 1.46957 36 2V4ZM10 8C6.68629 8 4 10.6863 4 14V38C4 41.3137 6.68629 44 10 44H38C41.3137 44 44 41.3137 44 38V14C44 10.6863 41.3137 8 38 8H10Z" />
    <path d="M35.8163 32.5915C35.8658 32.5168 35.9146 32.4402 35.9628 32.3608C36.0751 32.1811 35.9179 31.9564 35.6933 32.0013C35.6141 32.0178 35.5349 32.0319 35.4557 32.044C35.4521 32.0446 35.4485 32.0451 35.4449 32.0457C35.0785 32.1007 34.7122 32.1134 34.3459 32.1262C34.2561 32.1293 34.1663 32.1324 34.0765 32.1361C34.0448 32.1361 34.0131 32.1368 33.9814 32.138C33.9233 32.1401 33.8651 32.1437 33.807 32.1474C33.7517 32.1508 33.6965 32.1543 33.6412 32.1564C33.6066 32.1578 33.5721 32.1586 33.5375 32.1586C30.5507 32.1586 27.7212 30.9679 25.6102 28.811C23.4993 26.6542 22.3315 23.8009 22.3315 20.7679C22.3315 19.1757 22.6549 17.6215 23.2667 16.2106C23.3165 16.0956 23.3683 15.9816 23.4219 15.8686C23.4252 15.8616 23.4286 15.8546 23.4319 15.8476C23.5942 15.5229 23.8724 15.0532 24.1152 14.6597C24.1289 14.6375 24.1425 14.6155 24.156 14.5939C24.201 14.5214 24.2444 14.452 24.2852 14.3873C24.3975 14.2076 24.2628 13.9604 24.0382 14.0054C23.9508 14.0156 23.854 14.0295 23.7472 14.0476L23.7428 14.0484C23.3884 14.1089 22.925 14.2171 22.3328 14.4023C22.3249 14.4048 22.317 14.4073 22.309 14.4098C22.1539 14.4591 22.0002 14.5116 21.848 14.567C17.2206 16.2526 14 20.7149 14 25.9353C14 32.6079 19.3223 38 25.8797 38C29.3139 38 32.4239 36.5204 34.5939 34.1194C34.6998 34.0022 34.8035 33.8828 34.9049 33.7613C34.9132 33.7513 34.9215 33.7413 34.9298 33.7313C35.2515 33.3527 35.5412 33.006 35.8126 32.5972C35.8138 32.5953 35.8151 32.5934 35.8163 32.5915Z" />
  </g>,
)
