import {
  evolve, pick, pipe,
} from 'ramda'
import firebase from 'firebase/compat/app'
import { collectionToArray, docToData, onError } from './helpers'
import { updateAuth } from './auth'

const inputFormat = {
  people: (v) => Number(v),
  babies: (v) => Number(v),
  displayName: (v) => String(v),
}

const validateInput = pipe(
  pick(Object.keys(inputFormat)),
  evolve(inputFormat),
)

export const getCollection = () => firebase.firestore().collection('users')

export const getByPlace = async (placeId) => (
  getCollection()
    .where('places', 'array-contains', placeId)
    .get()
    .catch(onError('db.user.getByPlace'))
    .then(collectionToArray)
)

/**
 * Allow access to potentially any user profile
 * @param {*} uid
 * @returns
 */
export const list = async () => getCollection()
  .get()
  .catch(onError('db.user.list'))
  .then(collectionToArray)

/**
 * Allow access to potentially any user profile
 * @param {*} uid
 * @returns
 */
export const get = async (uid) => getCollection()
  .doc(uid)
  .get()
  .then(docToData)
  .catch(onError('db.user.get'))

const exists = async (uid) => getCollection()
  .doc(uid)
  .get()
  .then(
    (ref) => ref.exists,
  )

const create = async (uid, payload) => {
  const { displayName } = payload
  const values = {
    babies: 0,
    people: 1,
    displayName,
  }

  return getCollection()
    .doc(uid)
    .set(values)
    .then(() => get(uid))
    .catch(onError('db.user.create'))
}

/**
 * Beware, updates both the auth user and the user profile in firestore
 */
export const update = (payload) => Promise.all([
  getCollection()
    .doc(firebase.auth().currentUser.uid)
    .set(validateInput(payload), { merge: true })
    .catch(onError('db.user.update')),
  updateAuth({ displayName: payload.displayName }),
])

/**
 * Creates the user profile if it does not exist for the user
 */
export const checkProfile = async () => {
  const user = firebase.auth().currentUser

  if (!user) return null
  const hasProfile = await exists(user.uid)
  if (hasProfile) return get(user.uid)

  return create(user.uid, { displayName: user.displayName })
}
