import React from 'react'
import { Spinner } from '@chakra-ui/react'
import { string } from 'prop-types'

const LoadingInline = (props) => (
  <Spinner {...props} />
)

LoadingInline.propTypes = {
  color: string,
}

LoadingInline.defaultProps = {
  color: 'red.500',
}

export default LoadingInline
