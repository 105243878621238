/* eslint-disable import/prefer-default-export */
import { useSelector } from 'react-redux'
import { pick } from 'ramda'
import { getBooking, getNewBooking } from '../../store/modules/booking/selectors'

/**
 * Generate values based on an existing booking or initial values provided as props
 */
export const useBookingValues = ({ placeId, values }) => {
  const { uid } = values
  const booking = useSelector((state) => (
    uid
      ? getBooking(state, placeId, uid)
      : getNewBooking(state, placeId, values)
  ))

  return booking
    ? pick([
      'babies',
      'comment',
      'displayName',
      'end',
      'owner',
      'ownerId',
      'people',
      'rooms',
      'start',
      'type',
      'uid',
    ], booking)
    : undefined
}
