import React from 'react'
import { Controller } from 'react-hook-form'
import {
  FormControl,
  FormLabel,
  SimpleGrid,
} from '@chakra-ui/react'
import TypeSelect from '../../components/Booking.TypeSelect'
import { TYPES } from '../../../../constants/booking'
import Footer from './Booking.Step.Footer'
import { propTypes, defaultProps } from './types'

const BookingStepOne = ({
  stepper,
  formState,
  control,
  values,
}) => (
  <>
    <FormControl
      textAlign="center"
      flex="1"
    >
      <SimpleGrid gridGap="8" justifyContent="center">
        <FormLabel fontSize="xl" textAlign="center" m="0">Où voulez vous loger ?</FormLabel>
        <Controller
          name="type"
          control={control}
          render={({
            field: { onChange, value },
          }) => (
            <TypeSelect
              value={value}
              onChange={onChange}
              options={TYPES}
              size="xl"
            />
          )}
        />
      </SimpleGrid>
    </FormControl>
    <Footer
      stepper={stepper}
      formState={formState}
      isValid={!!values.type}
    />
  </>
)

BookingStepOne.propTypes = propTypes
BookingStepOne.defaultProps = defaultProps

export default BookingStepOne
