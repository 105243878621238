/* eslint-disable consistent-return */
import { SET_PLACE, SET_PLACES } from './types'
import db from '../../../init/db'

export const setPlace = (payload) => ({
  type: SET_PLACE,
  payload,
})

export function fetchPlace({ placeId }) {
  return async (dispatch) => {
    // @todo do not refetch if no change => use snapshot ?

    const payload = await db.place.get(placeId)
    if (!payload) return
    dispatch({ type: SET_PLACE, payload })
  }
}

export function fetchPlaces() {
  return async (dispatch) => {
    const payload = await db.place.list()
    if (!payload) return
    dispatch({ type: SET_PLACES, payload })
  }
}
