import reportWebVitals from './reportWebVitals'
import './index.css'
import './init'
import './App'
import './i18n'

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
