import { useEffect } from 'react'
import db from '../init/db'
import store from '../store'
// import { addBookings, removeBookings, replaceBookings } from '../store/modules/booking/actions'
import { addBookings, removeBookings } from '../store/modules/booking/actions'
import { docToData } from '../init/db/helpers'

const snapshotToStore = (snapshot, placeId) => {
  const { add, remove } = snapshot
    .docChanges()
    .reduce(
      (accu, change) => {
        // the actual firestore doc.id
        if (change.type === 'removed') accu.remove.push(change.doc.id)
        else accu.add.push(docToData(change.doc))
        return accu
      },
      { add: [], remove: [] },
    )

  if (add.length) {
    store.dispatch(
      addBookings({ placeId, bookings: add }),
    )
  }
  if (remove.length) {
    store.dispatch(
      removeBookings({ placeId, bookingIds: remove }),
    )
  }
}

const useBookingSnapshot = ({ placeId }) => {
  useEffect(() => {
    const ref = db.booking.getCollection({ placeId })
    return ref.onSnapshot(
      (snapshot) => snapshotToStore(snapshot, placeId),
    )
  }, [placeId])
}

export default useBookingSnapshot

/**
 * @deprecated ? Currently not relevant because we can not filter by date range
 *
 export const useBookingList = ({ placeId, range = {} }) => {
   useEffect(() => {
     const { start, end } = range
     if (!(start && end)) throw new Error('start and end parameters are mandatory')

     const fetchList = async () => {
       const bookings = await db.booking.list({ placeId, start, end })

       // Get bookingIds matching the range
       store.dispatch(replaceBookings({
         placeId,
         bookings,
         range,
       }))
     }
     fetchList()
     // return snapshotToStore(snapshot, placeId),
   }, [placeId, range])
 }
 */
