/* eslint-disable import/prefer-default-export */

import {
  areIntervalsOverlapping, eachDayOfInterval, subDays,
} from 'date-fns'

import { Event } from 'react-big-calendar'
import { BookingEvent, BookingEventDate } from '../../types/booking'
import { getDateId } from '../../helpers/date'
import {
  DateWithEvents, EventSums, KeyofEventSums, EventMappingByDay,
} from './types'

/**
 * Convert a booking event to a number of nights
 * - A night starts on the arrival date
 * - No night is added on the departure date
 *
 * takes an accumulator and an event
 * add an entry for each night and return the accumulator
 * aka duplicate the event as many times as there is nights in it
 */
const eventToNightReducer = (
  accu: EventMappingByDay,
  event: BookingEvent,
) => {
  const { start } = event
  const end = subDays(event.end, 1)
  // Single day events has no night
  if (end < start) return accu

  eachDayOfInterval(
    {
      start,
      end,
    },
  ).forEach(
    (date) => {
      // eslint-disable-next-line no-param-reassign
      accu[getDateId(date)] = accu[getDateId(date)]
        ? [...accu[getDateId(date)], { ...event, date }]
        : [{ ...event, date }]
    },
  )
  return accu
}

/**
 * Simple reducer using eventToNightsReducer
 */
const eventsToNight = (
  events: BookingEvent[],
): EventMappingByDay => (
  events.reduce(eventToNightReducer, {})
)

/**
 * Make the sums of metrics for a given day, based on events
 */
const buildSums = (events: BookingEventDate[]) => {
  const sums = {
    people: 0,
    babies: 0,
    house: 0,
    tent: 0,
  }
  if (!events) return sums

  return events.reduce(
    (accu, {
      people,
      babies,
      rooms,
      type,
    }): EventSums => {
      const add = (prop: KeyofEventSums, value?: number) => accu[prop] + (value || 0)

      return {
        people: add('people', people),
        babies: add('babies', babies),
        house: type === 'house' ? add('house', rooms) : accu.house,
        tent: type === 'tent' ? add('tent', rooms) : accu.tent,
      }
    },
    sums,
  )
}

/**
 * Build the event report including sums and grouping by date
 * THIS IS BASED ON NIGHTS (aka we drop the last date of booking entries)
 */
export const getNightsReport = (
  events: BookingEvent[],
  start: Date,
  end: Date,
): DateWithEvents[] => {
  const monthEvents = events.filter((event: Event): Boolean => {
    if (!(event.start && event.end)) return false
    return areIntervalsOverlapping(
      {
        start: event.start,
        end: event.end,
      },
      { start, end },
    )
  })

  const nightsWithEvents = eventsToNight(monthEvents)

  return eachDayOfInterval({ start, end }).map(
    (date) => {
      const dateId = getDateId(date)
      const nightEvents = nightsWithEvents[dateId]
      return {
        date,
        dateId,
        events: nightEvents,
        sums: buildSums(nightEvents),
      }
    },
  )
}
