import React from 'react'
import { Box } from '@chakra-ui/react'
import { Place } from '../../types'

import useResource from '../../hooks/useResource'

const Header = ({ place, ...props }) => {
  const { resource } = useResource(place?.picture)

  return (
    <Box
      backgroundImage={resource?.url}
      backgroundSize="cover"
      backgroundPosition="center center"
      height="160px"
      borderRadius="xl"
      {...props}
    />
  )
}

Header.propTypes = {
  place: Place.isRequired,
}

export default Header
