import React, { ChangeEvent, useState } from 'react'
import firebase from 'firebase/compat/app'
import {
  Button, FormControl, FormErrorMessage, Input, Text,
} from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { LoadingFullpage } from '../Loading'
import Card, { CardProps } from '../Card'

const registerCode = firebase.functions().httpsCallable('registerCode')

const PlaceCode = (props: CardProps) => {
  const [code, setCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const history = useHistory()

  const onSubmit = async () => {
    if (!code) return
    setLoading(true)

    try {
      const result = await registerCode({ code })
      setLoading(false)

      if (result && result.data) {
        history.push(`/place/${result.data}`)
      } else {
        setError('Verifiez votre saisie ou votre code.')
      }
    } catch (e) {
      setLoading(false)
      setError('Il y a eu un problème...')
    }
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>) => setCode(event.target.value)

  return (
    <Card {...props} position="relative">
      <Text size="lg" fontWeight="bold">
        Vous avez un code ?
      </Text>
      <Text size="md" marginBottom={6}>
        Saisissez-le pour rejoindre votre agenda.
      </Text>

      <FormControl isInvalid={!!error}>
        <Input
          id="placeCode"
          textAlign="center"
          placeholder="Votre code"
          onChange={onChange}
          autoComplete="off"
          autoCorrect="off"
          bg="white"
        />

        {error
          ? <FormErrorMessage>{error}</FormErrorMessage>
          : null}
      </FormControl>

      <Button
        onClick={onSubmit}
        marginY={4}
        isFullWidth
        disabled={!code}
      >
        Envoyer
      </Button>

      {
        loading ? (
          <LoadingFullpage
            position="absolute"
            background="rgba(255,255,255,.7)"
            zIndex="5"
          />
        ) : null
      }
    </Card>
  )
}

export default PlaceCode
