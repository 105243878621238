/* eslint-disable import/prefer-default-export */
import { areIntervalsOverlapping, eachDayOfInterval } from 'date-fns'
import {
  add,
  assocPath,
  evolve,
  filter,
  flatten,
  map,
  memoizeWith,
  pathOr,
  pipe,
  reduce,
  values,
} from 'ramda'
import { getDateId } from '../../../helpers/date'
import { LOAD_STATUSES } from '../../../constants/booking'

export const [
  LOAD_STATUS_OVERLOAD,
  LOAD_STATUS_FULL,
  LOAD_STATUS_HIGH,
  LOAD_STATUS_MEDIUM,
  LOAD_STATUS_LOW,
  LOAD_STATUS_EMPTY,
] = LOAD_STATUSES

/**
 * Splits a booking range into days
 * @param {Booking} booking
 * @returns {Array}
 */
export const bookingToDays = ({
  start, end, people, babies, rooms, type,
}) => eachDayOfInterval({
  // items are now preformated with beforeAdd by the reducer on ADD_BOOKINGS
  // start: start?.toDate(),
  // end: end?.toDate(),
  start,
  end,
}).map(
  (date) => ({
    date,
    dateId: getDateId(date),
    people,
    babies,
    rooms,
    type,
  }),
)

const defaultLoadItem = {
  people: 0,
  rooms: 0,
  babies: 0,
}

const calcStatus = ({ max, maxPeople }, loadItem) => {
  if (!loadItem) return LOAD_STATUS_EMPTY
  const { rooms, people } = loadItem
  if ((rooms > max) || (people > maxPeople)) return LOAD_STATUS_OVERLOAD
  if ((rooms === max) || (people === maxPeople)) return LOAD_STATUS_FULL
  if ((rooms >= max * 0.7) || (people >= max * 0.8)) return LOAD_STATUS_HIGH
  if ((rooms >= max * 0.3) || (people >= max * 0.5)) return LOAD_STATUS_MEDIUM
  if (people >= 0) return LOAD_STATUS_LOW
  return LOAD_STATUS_EMPTY
}

export const loadStatusGetter = (place) => (loads) => {
  const {
    peopleRoom,
    peopleTent,
    rooms,
    tents,
  } = place

  const tent = calcStatus({ max: tents, maxPeople: peopleRoom }, loads.tent)
  const house = calcStatus({ max: rooms, maxPeople: peopleTent }, loads.house)
  const global = calcStatus({
    max: tents + rooms,
    maxPeople: peopleTent + peopleRoom,
  }, {
    rooms: loads.tent?.rooms || 0 + loads.house?.rooms || 0,
    people: loads.tent?.people || 0 + loads.house?.people || 0,
  })

  return {
    global: Math.max(global, tent, house),
    tent,
    house,
  }
}

/**
 * Sum loads by date (dateId)
 * @param {Object} accu
 * @param {Booking} booking
 * @returns {Object}
 */
const dateLoadReducer = (accu, booking) => {
  const {
    dateId, people = 1, rooms = 0, babies = 0, type,
  } = booking
  const transforms = {
    people: add(people),
    rooms: add(rooms),
    babies: add(babies),
  }
  const previous = pathOr({ ...defaultLoadItem }, [dateId, type], accu)
  return assocPath(
    [dateId, type],
    evolve(transforms, previous),
    accu,
  )
}

export const filterByRange = ({ start, end }) => {
  const inRange = ({ start: s, end: e }) => areIntervalsOverlapping(
    // items are now preformated with beforeAdd by the reducer on ADD_BOOKINGS
    // { start: s.toDate(), end: e.toDate() },
    { start: s, end: e },
    { start, end },
  )
  return filter(inRange)
}

export const loadByDate = memoizeWith(
  JSON.stringify,
  ({ place, bookings, range }) => {
    if (!(place && bookings)) return []
    const items = range ? filterByRange(range)(bookings) : bookings
    const loadStatus = loadStatusGetter(place)

    return pipe(
      values,
      map(bookingToDays),
      flatten,
      reduce(dateLoadReducer, {}),
      map(
        (load) => ({
          ...load,
          status: loadStatus(load),
        }),
      ),
    )(items)
  },
)
