import React from 'react'
import firebase from 'firebase/compat/app'
import { FirebaseAuth } from 'react-firebaseui'
import { Box, Flex, Heading } from '@chakra-ui/react'
import Illustration from '../../components/Illustration'

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  // signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful.
  // Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      buttonColor: 'auto', // bad value but supported
    },
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      buttonColor: 'auto', // bad value but supported
      // disableSignUp: { status: true }, // , adminEmail: "johndoe@mail.com" }
    },
  ],
}

const SignIn = () => (
  <Flex
    align="center"
    height="100%"
    direction="column"
    justifyContent="center"
  >

    <Illustration name="favicon" />
    <Heading
      as="h1"
      size="lg"
      mt={2}
    >
      Le Sauvagenda
    </Heading>

    <Box marginY={8}>
      <FirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    </Box>
  </Flex>
)

export default SignIn
