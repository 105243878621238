import { createIcon } from '@chakra-ui/react'
import React from 'react'

const getDisplayName = (name: string, suffix: string) => {
  const parts = name.split('-')
  return [...parts, 'icon', suffix].map(
    (str) => str.charAt(0).toUpperCase() + str.slice(1),
  ).join('')
}

export const createIconSet = (
  name: string,
  fillPath: React.ReactElement | React.ReactElement[],
  outlinePath?: React.ReactElement | React.ReactElement[],
) => ({
  fill: createIcon({
    displayName: getDisplayName(name, 'fill'),
    viewBox: '0 0 48 48',
    path: <g fill="currentColor">{fillPath}</g>,
  }),
  outline: createIcon({
    displayName: getDisplayName(name, 'outline'),
    viewBox: '0 0 48 48',
    path: <g fill="currentColor">{outlinePath || fillPath}</g>,
  }),
})
