import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Box, SimpleGrid } from '@chakra-ui/react'
import { valuesShape, defaultValues } from './types'
import StepHeader from './Booking.Step.Header'
import useBookingSteps from './useBookingSteps'

const schema = yup.object({
  type: yup.mixed().oneOf(['house', 'tent']).required(),
  people: yup.number().min(1).max(30).required(),
  rooms: yup.number().min(1).max(10).required(),
  babies: yup.number().min(0).max(15).integer(),
  comment: yup.string().max(240),
  dates: yup.object().shape({
    start: yup.date().required(),
    end: yup.date().required(),
  }),
}).required()

const BookingSteps = ({
  initialValues,
  onSubmit,
}) => {
  const stepper = useBookingSteps()

  const {
    register,
    handleSubmit,
    // setValue,
    getValues,
    formState,
    trigger,
    control,
    watch,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  })

  const hasError = async (fields = []) => {
    if (!(fields && fields.length)) return false
    await trigger(fields)
    return fields.some((name) => !!formState.errors[name])
  }

  const handleStepSubmit = async (fields) => {
    const withErrors = await hasError(fields)
    if (withErrors) return
    stepper.move(1)
  }

  const Component = stepper.get().component
  const watchedValues = watch(['type'])

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid
          direction="column"
          height="540px"
          gridTemplateRows="auto 1fr auto"
          gridGap="8"
          alignItems="center"
        >
          <StepHeader
            values={getValues()}
            display={['none', 'grid']}
          />
          <Component
            onSubmit={handleStepSubmit}
            control={control}
            trigger={trigger}
            formState={formState} // errors ?
            register={register}
            values={{ ...getValues(), ...watchedValues }}
            stepper={stepper}
          />
        </SimpleGrid>
      </form>
    </Box>
  )
}

BookingSteps.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: valuesShape,
}

BookingSteps.defaultProps = {
  initialValues: defaultValues,
}

export default BookingSteps
