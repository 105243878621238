import React from 'react'
import { Image } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { getSrc } from './constants'

const Illustration = ({ name, size, ...rest }) => (
  <Image
    boxSize={size}
    src={getSrc(name)}
    alt={name}
    display="inline-block"
    verticalAlign="middle"
    {...rest}
  />
)

Illustration.propTypes = {
  size: PropTypes.string,
  name: PropTypes.string.isRequired,
}
Illustration.defaultProps = {
  // size: '8',
  size: undefined,
}

export default Illustration
