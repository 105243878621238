import { connect } from 'react-redux'
import Calendar from './Calendar'
import { getBookings } from '../../store/modules/booking/selectors'

const mapState = (state, ownProps) => {
  const { placeId } = ownProps
  return {
    events: getBookings(state, placeId),
  }
}

export default connect(mapState)(Calendar)
