import { SET_RESOURCE } from './types'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_RESOURCE: {
      return {
        ...state,
        [action.payload.uid]: action.payload,
      }
    }
    default:
      return state
  }
}
