import React from 'react'
import {
  Text,
} from '@chakra-ui/react'
import { format } from '../../../helpers/date'

import BaseValue from './Booking.Values.Base'

const ValueDate = (props) => {
  const { value } = props
  return (
    <BaseValue {...props}>
      <Text fontWeight="bold">
        {value ? format(value, 'd MMM') : '?'}
      </Text>
    </BaseValue>
  )
}
ValueDate.propTypes = BaseValue.propTypes
ValueDate.defaultProps = {
  ...BaseValue.defaultProps,
  color: 'primary.400',
}
export default ValueDate
