import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
} from '@chakra-ui/react'

const BookingStepNumberRow = ({
  name,
  register,
  errors,
  children,
}) => (
  <FormControl isInvalid={errors[name]}>
    <SimpleGrid
      templateColumns="1fr 80px"
      alignItems="center"
      gridGap="4"
    >
      <FormLabel
        m="0"
        display="flex"
        alignItems="center"
        gap="6px"
      >
        {children}
      </FormLabel>
      <Input {...register(name)} textAlign="center" bg="white" />
    </SimpleGrid>
    <FormErrorMessage display="block" textAlign="right">
      {errors[name] ? errors[name].message : ''}
    </FormErrorMessage>
  </FormControl>
)

BookingStepNumberRow.propTypes = {
  name: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

BookingStepNumberRow.defaultProps = {
  children: null,
  name: '',
}

export default BookingStepNumberRow
