/* eslint-disable import/prefer-default-export */
import { AUTHENTICATE, REPUDIATE } from './types'
// import db from '../../../init/db'

/**
 * Mark the authenticated user
 * @param {User} payload
 */
export const authenticate = (payload) => ({
  type: AUTHENTICATE,
  payload,
})

/**
 * Logs out the user
 * @todo clear storage
 * @param {User} payload
 */
export const repudiate = () => ({
  type: REPUDIATE,
})
