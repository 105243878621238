import React from 'react'
import {
  Text,
} from '@chakra-ui/react'
import { format } from '../../../helpers/date'

import BaseValue from './Booking.Values.Base'

const ValuePeriod = (props) => {
  const { value: [start, end], size } = props
  return (
    <Text fontWeight="bold" fontSize={size} textAlign="center">
      {`du ${format(start)} au ${format(end)}`}
    </Text>
  )
}
ValuePeriod.propTypes = BaseValue.propTypes
ValuePeriod.defaultProps = BaseValue.defaultProps

export default ValuePeriod
