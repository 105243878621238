/* eslint-disable import/prefer-default-export */
import { format as fnsFormat } from 'date-fns'
import { getLocales } from '../i18n/lang'

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
export const format = (date, formatStr = 'PP') => fnsFormat(date, formatStr, {
  locale: getLocales(),
})

export const getDateId = (date) => format(date, 'yyyyMMdd')
