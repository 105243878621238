import React from 'react'

import {
  Badge, Box, Heading, Image,
} from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { Place } from '../../types/place'
// import { LoadingOverlay } from '../Loading'
import useResource from '../../hooks/useResource'
import Card, { CardProps } from '../Card'
import locales from '../../constants/locales'

interface PlaceRowProps extends CardProps {
  place: Place
}

const PlaceCard = ({
  place,
  ...rest
}: PlaceRowProps) => {
  const { resource } = useResource(place?.picture)
  const history = useHistory()
  const {
    uid,
    name,
    peopleRoom,
    peopleTent,
    rooms,
    tents,
  } = place

  return (
    <Card
      onClick={() => history.push(`place/${uid}`)}
      scrollMarginY="4"
      {...rest}
    >
      <Image
        borderRadius="md"
        src={resource?.url}
        alt={name}
        boxSize="8rem"
        objectFit="cover"
      />
      <Box p="6" textAlign="left">
        <Heading size="lg" mb={2}>
          {name}
          <Badge borderRadius="full" marginLeft={2} py="1" px="2" colorScheme="primary">
            {`${peopleRoom + peopleTent}`}
          </Badge>
        </Heading>
        <Box display="flex" alignItems="baseline">
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            ml="2"
          >
            {`${rooms} ${locales.house.rooms} `}
            &bull;
            {` ${tents} ${locales.tent.rooms}`}
          </Box>
        </Box>
      </Box>
    </Card>
  )
}

export default PlaceCard
