/* eslint-disable no-template-curly-in-string */
import { setLocale } from 'yup'

const TYPE_ERRORS = {
  string: 'Entrez du texte',
  number: 'Entrez un nombre',
  date: 'Entrez une date',
  boolean: 'Renseignez ce champ',
  default: 'Champ invalide',
}

setLocale({
  // use constant translation keys for messages without values
  mixed: {
    default: 'Invalide',
    required: 'Obligatoire',
    notType: ({ type }) => TYPE_ERRORS[type] || TYPE_ERRORS.default,
  },
  // use functions to generate an error object that includes the value from the schema
  number: {
    integer: 'Entrez un nombre entier',
    positive: 'Entrez un nombre supérieur ou égal à zéro',
    negative: 'Entrez un nombre inférieur ou égal à zéro',
    min: 'Doit être supérieur ou égal à ${min}',
    max: 'Doit être inférieur ou égal à ${max}',
  },
})
