/* eslint-disable import/prefer-default-export */
import firebase from 'firebase/compat/app'

export const updateAuth = async (values) => {
  const user = firebase.auth().currentUser
  // Prevent clearing the display name
  const { displayName = user.displayName, ...rest } = values
  return user.updateProfile({
    displayName,
    ...rest,
  })
}
