import firebase from 'firebase/compat/app'
import { onFirestoreError } from './errors'
import { collectionToArray, docToData, onError } from './helpers'

export const getCollection = () => firebase.firestore().collection('places')

export const get = (placeId) => getCollection()
  .doc(placeId)
  .get()
  .catch(onFirestoreError)
  .then((res) => (res ? docToData(res) : null))
  .catch(onError('db.place.get'))

/**
 * This will return only the list of places
 * the current user is registered on
 *
 * @note there is no way to filter on uid currently
 */
export const list = () => getCollection()
  .where('members', 'array-contains', firebase.auth().currentUser.uid)
  .get()
  .catch(onFirestoreError)
  .then((res) => (res ? collectionToArray(res) : null))
  .catch(onError('db.place.list'))
