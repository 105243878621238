import React from 'react'
import { Flex } from '@chakra-ui/react'
import { Controller } from 'react-hook-form'
import { DateUtils } from 'react-day-picker'
import Footer from './Booking.Step.Footer'
import { propTypes, defaultProps } from './types'
import Picker from '../../../DatePicker'

const BookingStepDates = ({
  stepper,
  control,
  trigger,
  formState,
}) => {
  const handleDayClick = ({
    name, day, onChange, value,
  }) => {
    const { start, end } = value
    const range = DateUtils.addDayToRange(day, { from: start, to: end })

    onChange({
      start: range.from,
      end: range.to,
    })
    trigger(name)
  }

  const validateRange = ({ start, end }) => Boolean(
    start && end && (Date(start) <= Date(end)),
  )

  return (
    <>
      <Flex justifyContent="center">
        <Controller
          name="dates"
          control={control}
          rules={{
            validate: validateRange,
          }}
          render={({
            field: { name, onChange, value },
          }) => (
            <>
              <Picker
                className="Selectable"
                numberOfMonths={2}
                month={value.start}
                selectedDays={[
                  value.start, { from: value.start, to: value.end },
                ]}
                onDayClick={(day) => handleDayClick({
                  name, day, onChange, value,
                })}
              />
            </>
          )}
        />
      </Flex>
      <Footer
        formState={formState}
        stepper={stepper}
        isValid={formState.isValid}
      />
    </>
  )
}

BookingStepDates.propTypes = propTypes
BookingStepDates.defaultProps = defaultProps

export default BookingStepDates
