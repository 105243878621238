import React from 'react'
import { IconProps } from '@chakra-ui/react'
import { baby } from './IconSet.Baby'
import { calendarDay, calendarTable, calendarMoon } from './IconSet.Calendar'
import { chat } from './IconSet.Chat'
import {
  dotsHorizontal,
  dot,
  plus,
  minus,
  cross,
  angleDown,
  angleRight,
  angleLeft,
  angleUp,
  angleSmallDown,
  angleSmallRight,
  angleSmallLeft,
  angleSmallUp,
} from './IconSet.Signs'
import { house } from './IconSet.House'
import { moon } from './IconSet.Moon'
import { people } from './IconSet.People'
import { tent } from './IconSet.Tent'

const ICONSETS = {
  'angle-down': angleDown,
  'angle-right': angleRight,
  'angle-left': angleLeft,
  'angle-up': angleUp,
  'angle-small-down': angleSmallDown,
  'angle-small-right': angleSmallRight,
  'angle-small-left': angleSmallLeft,
  'angle-small-up': angleSmallUp,
  baby,
  'calendar-day': calendarDay,
  'calendar-table': calendarTable,
  'calendar-moon': calendarMoon,
  chat,
  cross,
  dot,
  dots: dotsHorizontal,
  house,
  minus,
  moon,
  people,
  plus,
  tent,
}

export type IconName = keyof typeof ICONSETS

const Icon = (
  props: IconProps & {
    name: IconName,
    variant?: 'outline' | 'fill'
  },
) => {
  const { name, variant, ...rest } = props
  const icn = ICONSETS[name]

  const Component = icn[variant || 'fill']

  return <Component {...rest} />
}

Icon.defaultProps = {
  variant: 'fill',
}

export default Icon
