import { Box, BoxProps, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPlaces } from '../../store/modules/place/actions'
import { getPlaces } from '../../store/modules/place/selectors'
import { LoadingOverlay } from '../Loading'

import PlaceCard from './PlaceCard'

interface PlaceListProps extends BoxProps {
  placeIds: string[]
}

const PlaceList = ({
  placeIds,
  ...rest
}: PlaceListProps) => {
  const dispatch = useDispatch()
  const places = useSelector(
    (state) => getPlaces(state, placeIds),
  )
  useEffect(
    () => {
      dispatch(fetchPlaces())
    },
    [],
  )

  if (!places.length) return <LoadingOverlay />

  return (
    <Box {...rest}>
      <Text size="md" mb={4}>
        {'Quelle propriété voulez vous visiter aujourd\'hui ?'}
      </Text>

      {
        places.map(
          (place) => (
            <PlaceCard
              key={place.uid}
              place={place}
              mb={4}
            />
          ),
        )
      }
    </Box>
  )
}

export default PlaceList
