import React from 'react'
import { Text } from '@chakra-ui/react'

import BaseValue from './Booking.Values.Base'

const ValueNumber = (props) => {
  const { value } = props
  return (
    <BaseValue {...props}>
      <Text fontWeight="bold">{value === undefined ? '?' : value}</Text>
    </BaseValue>
  )
}
ValueNumber.propTypes = BaseValue.propTypes
ValueNumber.defaultProps = BaseValue.defaultProps

export default ValueNumber
