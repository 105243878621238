import React from 'react'
import PropTypes from 'prop-types'

import FormSteps from './steps'

const BookingForm = ({
  placeId,
  initialValues,
  onSave,
  onSuccess,
}) => {
  const handleSave = async (data) => {
    if (!onSave) return null
    const {
      type,
      rooms,
      people,
      babies,
      comment,
      dates,
    } = data

    const res = await onSave({
      placeId,
      type,
      rooms,
      people,
      babies,
      comment,
      start: dates.start,
      end: dates.end,
    })
    if (onSuccess) await onSuccess(res)
    return res
  }

  /**
   * Group dates to enable the single RHF controller
   */
  const {
    start,
    end,
    ...rest
  } = initialValues

  return (
    <FormSteps
      onSubmit={handleSave}
      initialValues={{
        ...rest,
        dates: {
          start,
          end,
        },
      }}
    />
  )
}

BookingForm.propTypes = {
  placeId: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({
    start: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    end: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    type: PropTypes.oneOf(['house', 'tent']),
    rooms: PropTypes.number,
    babies: PropTypes.number,
  }),
  onSave: PropTypes.func,
  onSuccess: PropTypes.func,
}

BookingForm.defaultProps = {
  initialValues: {
    type: 'house',
    rooms: 1,
    babies: 0,
  },
  onSave: null,
  onSuccess: null,
}

export default BookingForm
