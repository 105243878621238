import { Heading, SimpleGrid } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { UserContext } from '../../context/UserProvider'
import Illustration from '../Illustration'
import HeaderMenu from './Header.Menu'

const Header = () => {
  const user = useContext(UserContext)

  return (
    <SimpleGrid
      gridTemplateColumns="32px 1fr auto"
      alignItems="start"
      gridGap={2}
      as="header"
      className="svg-header"
      zIndex={10}
      position="fixed"
      top={0}
      width="100%"
      height="16"
      padding={4}
      bgGradient="linear(45deg, primary.100, primary.200)"
      color="secondary.300"
    >
      <Illustration name="favicon" />
      <Heading
        as="h1"
        size="lg"
      >
        <Link to="/">Le Sauvagenda</Link>
      </Heading>
      {
        user
          ? <HeaderMenu />
          : null
      }
    </SimpleGrid>
  )
}

export default Header
