import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider as StoreProvider } from 'react-redux'
import {
  Box,
  ChakraProvider, Container, Flex,
} from '@chakra-ui/react'
import firebase from 'firebase/compat/app'
import theme from './style/theme'
import UserProvider from './context/UserProvider'
import Routes from './router'
import Header from './components/Header'
import store from './store'

import './style/main.css'

const PublicApp = () => (
  <Flex
    align="center"
    height="100%"
    direction="column"
    justifyContent="center"
  >
    <Container maxW="container.lg" py={[4, 8]} px={[4, 4]}>
      <Routes />
    </Container>
  </Flex>
)

const SecuredApp = () => (
  <Box mt="16">
    <Header />
    <Container maxW="container.lg" py={[4, 8]} px={[4, 4]}>
      <Routes />
    </Container>
  </Box>
)

function App() {
  return (
    <Router>
      {
        firebase.auth().currentUser
          ? <SecuredApp />
          : <PublicApp />
      }
    </Router>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <ChakraProvider theme={theme} resetCSS>
        <UserProvider>
          <App />
        </UserProvider>
      </ChakraProvider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
