import React, { FC } from 'react'
import { Flex, FlexProps } from '@chakra-ui/react'

export interface CardProps extends FlexProps {
  onClick?: (payload: any) => void;
}

const Card: FC<CardProps> = ({
  onClick = undefined,
  children,
  ...rest
}) => {
  const hover = onClick ? { bg: 'lightestgray.100' } : undefined
  const active = onClick ? { bg: 'lightestgray.200', transform: 'scale(.99)' } : undefined

  return (
    <Flex
      as={onClick ? 'button' : 'div'}
      width="full"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      transition="transform 200ms ease"
      padding="2"
      _hover={hover}
      _active={active}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Flex>
  )
}

export default Card
