import React from 'react'
import {
  SimpleGrid,
} from '@chakra-ui/react'
import { getTypeDefinition } from '../../../../constants/booking'
import { valuesShape } from './types'
import Values from '../../components/Booking.Values'

const BookingStepHeader = ({
  values,
  ...rest
}) => {
  const {
    type,
    rooms,
    people,
    babies,
    dates,
  } = values
  const { roomLabel = 'Place(s)' } = getTypeDefinition(type) || {}

  return (
    <SimpleGrid
      columns="6"
      pb="8"
      borderBottom="2px"
      borderColor="secondary.500"
      {...rest}
    >
      <Values.Type value={type} color="danger.200" />
      <Values.Date value={dates.start} label="Début" />
      <Values.Date value={dates.end} label="Fin" />
      <Values.Number value={rooms} label={roomLabel} />
      <Values.Number value={people} label="> 3ans" />
      <Values.Number value={babies} label="Bébé(s)" />
    </SimpleGrid>
  )
}

BookingStepHeader.propTypes = {
  values: valuesShape,
}

BookingStepHeader.defaultProps = {
  values: {},
}

export default BookingStepHeader
