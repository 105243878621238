import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  ButtonGroup,
} from '@chakra-ui/react'
import Values from './Booking.Values'

const getVariant = (current, value) => {
  if (current === value) return 'solid'
  if (!current) return 'ghost'
  return 'ghost'
}

const BookingTypeSelect = ({
  onChange,
  value,
  options,
  isAttached,
  ...rest
}) => (
  <ButtonGroup
    spacing="4"
    isAttached={isAttached}
    {...rest}
  >
    {
      options.map(
        ({ value: val }) => (
          <Button
            key={val}
            variant={getVariant(value, val)}
            onClick={() => onChange(val)}
          >
            <Values.Type
              value={val}
              variant="illustration"
              iconSize="6em"
            />
          </Button>
        ),
      )
    }
  </ButtonGroup>
)

const valueType = PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
const optionType = PropTypes.shape({
  icon: PropTypes.string,
  value: valueType,
})

BookingTypeSelect.propTypes = {
  value: valueType,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(optionType).isRequired,
  isAttached: PropTypes.bool,
}

BookingTypeSelect.defaultProps = {
  value: undefined,
  onChange: null,
  isAttached: false,
}

export default BookingTypeSelect
