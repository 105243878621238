import React from 'react'
import { createIconSet } from './helpers'

export const dotsHorizontal = createIconSet(
  'dots-horizontal',
  <g>
    <path d="M8.65696 19C6.079 19 4 21.079 4 23.657C4 26.2349 6.079 28.3139 8.65696 28.3139C11.2349 28.3139 13.3139 26.2349 13.3139 23.657C13.2723 21.079 11.1933 19 8.65696 19Z" />
    <path d="M24 19.2079C21.422 19.2079 19.343 21.2869 19.343 23.8649C19.343 26.4428 21.422 28.5218 24 28.5218C26.578 28.5218 28.657 26.4428 28.657 23.8649C28.6154 21.2869 26.5364 19.2079 24 19.2079Z" />
    <path d="M39.343 19.2079C36.7651 19.2079 34.6861 21.2869 34.6861 23.8649C34.6861 26.4428 36.7651 28.5218 39.343 28.5218C41.921 28.5218 44 26.4428 44 23.8649C44 21.2869 41.921 19.2079 39.343 19.2079Z" />
  </g>,
  <g>
    <path d="M8.65678 19C6.07882 19 3.99982 21.079 3.99982 23.657C3.99982 26.2349 6.07882 28.3139 8.65678 28.3139C11.2347 28.3139 13.3137 26.2349 13.3137 23.657C13.2722 21.079 11.1932 19 8.65678 19ZM8.65678 26.2349C7.24306 26.2349 6.07882 25.0707 6.07882 23.657C6.07882 22.2017 7.20148 21.079 8.65678 21.079C10.0705 21.079 11.2347 22.2432 11.2347 23.657C11.2347 25.0707 10.0705 26.2349 8.65678 26.2349Z" />
    <path d="M23.9998 19.2079C21.4219 19.2079 19.3428 21.2869 19.3428 23.8649C19.3428 26.4428 21.4219 28.5218 23.9998 28.5218C26.5778 28.5218 28.6568 26.4428 28.6568 23.8649C28.6152 21.2869 26.5362 19.2079 23.9998 19.2079ZM23.9998 26.4428C22.5861 26.4428 21.4219 25.2786 21.4219 23.8649C21.4219 22.4511 22.5861 21.2869 23.9998 21.2869C25.4135 21.2869 26.5778 22.4511 26.5778 23.8649C26.5778 25.2786 25.4135 26.4428 23.9998 26.4428Z" />
    <path d="M39.3428 19.2079C36.7649 19.2079 34.6859 21.2869 34.6859 23.8649C34.6859 26.4428 36.7649 28.5218 39.3428 28.5218C41.9208 28.5218 43.9998 26.4428 43.9998 23.8649C43.9998 21.2869 41.9208 19.2079 39.3428 19.2079ZM39.3428 26.4428C37.9291 26.4428 36.7649 25.2786 36.7649 23.8649C36.7649 22.4511 37.9291 21.2869 39.3428 21.2869C40.7566 21.2869 41.9208 22.4511 41.9208 23.8649C41.9208 25.2786 40.7566 26.4428 39.3428 26.4428Z" />
  </g>,
)

export const dot = createIconSet(
  'dot',
  <path d="M23.657 19C21.079 19 19 21.079 19 23.657C19 26.2349 21.079 28.3139 23.657 28.3139C26.2349 28.3139 28.3139 26.2349 28.3139 23.657C28.2723 21.079 26.1933 19 23.657 19Z" />,
  <path d="M23.657 19C21.079 19 19 21.079 19 23.657C19 26.2349 21.079 28.3139 23.657 28.3139C26.2349 28.3139 28.3139 26.2349 28.3139 23.657C28.2723 21.079 26.1933 19 23.657 19Z" />,
)

export const plus = createIconSet(
  'plus',
  <path fillRule="evenodd" clipRule="evenodd" d="M26 8C26 6.89543 25.1046 6 24 6C22.8954 6 22 6.89543 22 8V22H8C6.89543 22 6 22.8954 6 24C6 25.1046 6.89543 26 8 26H22V40C22 41.1046 22.8954 42 24 42C25.1046 42 26 41.1046 26 40V26H40C41.1046 26 42 25.1046 42 24C42 22.8954 41.1046 22 40 22H26V8Z" />,
  <path fillRule="evenodd" clipRule="evenodd" d="M26 8C26 6.89543 25.1046 6 24 6C22.8954 6 22 6.89543 22 8V22H8C6.89543 22 6 22.8954 6 24C6 25.1046 6.89543 26 8 26H22V40C22 41.1046 22.8954 42 24 42C25.1046 42 26 41.1046 26 40V26H40C41.1046 26 42 25.1046 42 24C42 22.8954 41.1046 22 40 22H26V8Z" />,
)

export const minus = createIconSet(
  'minus',
  <path d="M6 24C6 22.8954 6.89543 22 8 22H40C41.1046 22 42 22.8954 42 24V24C42 25.1046 41.1046 26 40 26H8C6.89543 26 6 25.1046 6 24V24Z" />,
  <path d="M6 24C6 22.8954 6.89543 22 8 22H40C41.1046 22 42 22.8954 42 24V24C42 25.1046 41.1046 26 40 26H8C6.89543 26 6 25.1046 6 24V24Z" />,
)

export const cross = createIconSet(
  'cross',
  <path fillRule="evenodd" clipRule="evenodd" d="M36.7279 14.1005C37.509 13.3195 37.509 12.0531 36.7279 11.2721C35.9469 10.491 34.6805 10.491 33.8995 11.2721L24 21.1716L14.1005 11.2721C13.3195 10.491 12.0531 10.491 11.2721 11.2721C10.491 12.0531 10.491 13.3195 11.2721 14.1005L21.1716 24L11.2721 33.8995C10.491 34.6805 10.491 35.9469 11.2721 36.7279C12.0531 37.509 13.3195 37.509 14.1005 36.7279L24 26.8284L33.8995 36.7279C34.6805 37.509 35.9469 37.509 36.7279 36.7279C37.509 35.9469 37.509 34.6805 36.7279 33.8995L26.8284 24L36.7279 14.1005Z" />,
  <path fillRule="evenodd" clipRule="evenodd" d="M36.7279 14.1005C37.509 13.3195 37.509 12.0531 36.7279 11.2721C35.9469 10.491 34.6805 10.491 33.8995 11.2721L24 21.1716L14.1005 11.2721C13.3195 10.491 12.0531 10.491 11.2721 11.2721C10.491 12.0531 10.491 13.3195 11.2721 14.1005L21.1716 24L11.2721 33.8995C10.491 34.6805 10.491 35.9469 11.2721 36.7279C12.0531 37.509 13.3195 37.509 14.1005 36.7279L24 26.8284L33.8995 36.7279C34.6805 37.509 35.9469 37.509 36.7279 36.7279C37.509 35.9469 37.509 34.6805 36.7279 33.8995L26.8284 24L36.7279 14.1005Z" />,
)

export const angleDown = createIconSet(
  'angle-down',
  <path d="M24 34.338C22.6867 34.3404 21.3859 34.0833 20.1723 33.5815C18.9587 33.0798 17.8561 32.3431 16.928 31.414L0.586004 15.07C0.221688 14.6928 0.0200988 14.1876 0.0246556 13.6632C0.0292124 13.1388 0.23955 12.6372 0.610367 12.2664C0.981183 11.8955 1.48281 11.6852 2.0072 11.6807C2.53159 11.6761 3.0368 11.8777 3.414 12.242L19.756 28.586C20.8826 29.7096 22.4088 30.3406 24 30.3406C25.5912 30.3406 27.1174 29.7096 28.244 28.586L44.586 12.242C44.7705 12.051 44.9912 11.8986 45.2352 11.7938C45.4792 11.689 45.7416 11.6338 46.0072 11.6315C46.2728 11.6292 46.5361 11.6798 46.7819 11.7804C47.0277 11.8809 47.251 12.0294 47.4388 12.2172C47.6266 12.405 47.7751 12.6283 47.8756 12.8741C47.9762 13.1199 48.0268 13.3832 48.0245 13.6488C48.0222 13.9144 47.967 14.1768 47.8622 14.4208C47.7574 14.6648 47.605 14.8855 47.414 15.07L31.072 31.414C30.1439 32.3431 29.0413 33.0798 27.8277 33.5815C26.6141 34.0833 25.3133 34.3404 24 34.338Z" />,
)

export const angleRight = createIconSet(
  'angle-right',
  <path d="M36 31C35.4696 30.9999 34.961 30.7891 34.586 30.414L25.414 21.242C25.0337 20.877 24.5271 20.6732 24 20.6732C23.4729 20.6732 22.9663 20.877 22.586 21.242L13.414 30.414C13.0368 30.7783 12.5316 30.9799 12.0072 30.9753C11.4828 30.9708 10.9812 30.7604 10.6104 30.3896C10.2396 30.0188 10.0292 29.5172 10.0247 28.9928C10.0201 28.4684 10.2217 27.9632 10.586 27.586L19.758 18.414C20.9012 17.3231 22.4208 16.7144 24.001 16.7144C25.5812 16.7144 27.1008 17.3231 28.244 18.414L37.414 27.586C37.6936 27.8657 37.884 28.222 37.9612 28.6099C38.0383 28.9978 37.9987 29.3999 37.8474 29.7653C37.696 30.1307 37.4397 30.443 37.1109 30.6628C36.7821 30.8826 36.3955 30.9999 36 31Z" />,
)

export const angleLeft = createIconSet(
  'angle-left',
  <path d="M34.344 48C33.8136 47.9999 33.305 47.7891 32.93 47.414L16.586 31.07C14.7113 29.1947 13.6581 26.6517 13.6581 24C13.6581 21.3484 14.7113 18.8053 16.586 16.93L32.93 0.586006C33.3072 0.22169 33.8124 0.0201007 34.3368 0.0246575C34.8612 0.0292144 35.3628 0.239552 35.7336 0.610368C36.1045 0.981185 36.3148 1.48281 36.3193 2.0072C36.3239 2.5316 36.1223 3.0368 35.758 3.41401L19.414 19.758C18.2892 20.8832 17.6573 22.409 17.6573 24C17.6573 25.591 18.2892 27.1168 19.414 28.242L35.758 44.586C36.0376 44.8657 36.228 45.222 36.3052 45.6099C36.3823 45.9979 36.3427 46.3999 36.1914 46.7653C36.04 47.1307 35.7837 47.443 35.4549 47.6628C35.1261 47.8826 34.7395 47.9999 34.344 48Z" />,
)

export const angleUp = createIconSet(
  'angle-up',
  <path d="M47.414 32.586L31.072 16.242C29.1945 14.37 26.6513 13.3187 24 13.3187C21.3487 13.3187 18.8055 14.37 16.928 16.242L0.586006 32.586C0.221689 32.9632 0.0201007 33.4684 0.0246575 33.9928C0.0292144 34.5172 0.239552 35.0188 0.610368 35.3896C0.981185 35.7604 1.48281 35.9708 2.0072 35.9753C2.5316 35.9799 3.0368 35.7783 3.41401 35.414L19.756 19.07C20.8834 17.9479 22.4093 17.3179 24 17.3179C25.5907 17.3179 27.1166 17.9479 28.244 19.07L44.586 35.414C44.9632 35.7783 45.4684 35.9799 45.9928 35.9753C46.5172 35.9708 47.0188 35.7604 47.3896 35.3896C47.7605 35.0188 47.9708 34.5172 47.9754 33.9928C47.9799 33.4684 47.7783 32.9632 47.414 32.586Z" />,
)

export const angleSmallDown = createIconSet(
  'angle-small-down',
  <path d="M37.414 16.414C37.0389 16.0391 36.5303 15.8284 36 15.8284C35.4697 15.8284 34.9611 16.0391 34.586 16.414L25.414 25.586C25.0337 25.951 24.5271 26.1548 24 26.1548C23.4729 26.1548 22.9663 25.951 22.586 25.586L13.414 16.414C13.0368 16.0497 12.5316 15.8481 12.0072 15.8527C11.4828 15.8572 10.9812 16.0675 10.6104 16.4384C10.2395 16.8092 10.0292 17.3108 10.0247 17.8352C10.0201 18.3596 10.2217 18.8648 10.586 19.242L19.756 28.414C20.3132 28.9713 20.9746 29.4133 21.7027 29.7149C22.4307 30.0165 23.211 30.1717 23.999 30.1717C24.787 30.1717 25.5673 30.0165 26.2953 29.7149C27.0234 29.4133 27.6848 28.9713 28.242 28.414L37.414 19.242C37.7889 18.8669 37.9996 18.3583 37.9996 17.828C37.9996 17.2977 37.7889 16.7891 37.414 16.414Z" />,

)

export const angleSmallRight = createIconSet(
  'angle-small-right',
  <path d="M30.8 19.758L21.622 10.586C21.2412 10.2417 20.7425 10.057 20.2293 10.0702C19.7161 10.0833 19.2276 10.2934 18.8649 10.6568C18.5023 11.0202 18.2933 11.5092 18.2812 12.0224C18.2691 12.5357 18.4549 13.0339 18.8 13.414L27.972 22.586C28.3469 22.9611 28.5576 23.4697 28.5576 24C28.5576 24.5303 28.3469 25.0389 27.972 25.414L18.8 34.586C18.425 34.9613 18.2144 35.4702 18.2146 36.0007C18.2148 36.5312 18.4257 37.04 18.801 37.415C19.1763 37.79 19.6852 38.0006 20.2157 38.0004C20.7463 38.0002 21.255 37.7893 21.63 37.414L30.8 28.242C31.9248 27.1168 32.5567 25.591 32.5567 24C32.5567 22.409 31.9248 20.8832 30.8 19.758Z" />,

)

export const angleSmallLeft = createIconSet(
  'angle-small-left',
  <path d="M21.2 25.414C20.8251 25.0389 20.6144 24.5303 20.6144 24C20.6144 23.4697 20.8251 22.9611 21.2 22.586L30.37 13.414C30.7343 13.0368 30.9359 12.5316 30.9313 12.0072C30.9268 11.4828 30.7165 10.9812 30.3456 10.6104C29.9748 10.2395 29.4732 10.0292 28.9488 10.0247C28.4244 10.0201 27.9192 10.2217 27.542 10.586L18.378 19.758C17.2532 20.8832 16.6213 22.409 16.6213 24C16.6213 25.591 17.2532 27.1168 18.378 28.242L27.55 37.414C27.9272 37.7783 28.4324 37.9799 28.9568 37.9754C29.4812 37.9708 29.9828 37.7605 30.3536 37.3896C30.7245 37.0188 30.9348 36.5172 30.9393 35.9928C30.9439 35.4684 30.7423 34.9632 30.378 34.586L21.2 25.414Z" />,

)

export const angleSmallUp = createIconSet(
  'angle-small-up',
  <path d="M47.414 32.586L31.072 16.242C29.1945 14.37 26.6513 13.3187 24 13.3187C21.3487 13.3187 18.8055 14.37 16.928 16.242L0.586006 32.586C0.221689 32.9632 0.0201007 33.4684 0.0246575 33.9928C0.0292144 34.5172 0.239552 35.0188 0.610368 35.3896C0.981185 35.7604 1.48281 35.9708 2.0072 35.9753C2.5316 35.9799 3.0368 35.7783 3.41401 35.414L19.756 19.07C20.8834 17.9479 22.4093 17.3179 24 17.3179C25.5907 17.3179 27.1166 17.9479 28.244 19.07L44.586 35.414C44.9632 35.7783 45.4684 35.9799 45.9928 35.9753C46.5172 35.9708 47.0188 35.7604 47.3896 35.3896C47.7605 35.0188 47.9708 34.5172 47.9754 33.9928C47.9799 33.4684 47.7783 32.9632 47.414 32.586Z" />,

)
