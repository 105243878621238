import React, { createContext, FC, ReactElement } from 'react'
import firebase from 'firebase/compat/app'
import { useAuthState } from 'react-firebase-hooks/auth'
import { LoadingFullpage } from '../components/Loading'
import { User } from '../types/user'

export const UserContext = createContext<User | undefined>(undefined)

interface UserProviderProps {
  children: ReactElement
}

const UserProvider: FC<UserProviderProps> = ({ children }) => {
  /** @todo handle error (third hook return value) */
  const [user, loading]: [User, boolean, any] = useAuthState(firebase.auth())

  return (
    <UserContext.Provider value={user}>
      {
        loading
          ? <LoadingFullpage />
          : children
      }
    </UserContext.Provider>
  )
}

export default UserProvider
