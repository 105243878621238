/* eslint-disable no-console */
import React, { useMemo } from 'react'
import { startOfMonth, endOfMonth } from 'date-fns'
import {
  TitleOptions, ViewProps, Navigate, NavigateAction,
} from 'react-big-calendar'
import { Box, useBreakpointValue } from '@chakra-ui/react'
import { BookingEvent } from '../../types/booking'
import { getNightsReport } from './helpers'

import { ReportViewRow, ReportViewHeader } from './Calendar.ReportView.Row'

const ReportView = (props: ViewProps<BookingEvent>) => {
  const {
    date, localizer, events, dayPropGetter, onSelectEvent,
  } = props

  const isSmall = useBreakpointValue({
    base: true,
    md: false,
  })

  const eventList = useMemo(
    () => getNightsReport(
      events || [],
      startOfMonth(new Date(date)),
      endOfMonth(new Date(date)),
    ),
    [events, date],
  )

  return (
    <Box className="booking-calendar-report">
      {
        isSmall
          ? null
          : <ReportViewHeader />
      }
      {
        eventList.map(
          (dateEvents) => (
            <ReportViewRow
              key={dateEvents.dateId}
              localizer={localizer}
              dayProps={
                dayPropGetter
                  ? dayPropGetter(dateEvents.date)
                  : {}
              }
              onEventClick={onSelectEvent}
              {...dateEvents}
              compact={isSmall}
            />
          ),
        )
      }
    </Box>
  )
}

ReportView.title = (date: Date, { localizer }: TitleOptions) => localizer.messages.reportTitle(date)

ReportView.navigate = (date: Date, action: NavigateAction, options: ViewProps<BookingEvent>) => {
  const { localizer } = options

  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -1, 'month')

    case Navigate.NEXT:
      return localizer.add(date, 1, 'month')

    default:
      return date
  }
}

export default ReportView
