const shadowToBorder = {
  boxShadow: 'none',
  border: '1px solid currentcolor',
  borderRadius: 'md',
  background: 'white',
  borderColor: 'lightgray.200',
}

const styles = {
  '#firebaseui_container': {
    fontFamily: 'body',

    '.firebaseui-title': {
      fontFamily: 'heading',
    },

    '.firebaseui-idp-button': {
      padding: '4',

      '&[data-provider-id="google.com"]': {
        background: 'primary.400',
        '.firebaseui-idp-text': { color: 'white' },
      },

      '&[data-provider-id="password"]': {
        background: 'secondary.300',
        '.firebaseui-idp-text': { color: 'white' },
      },

      '.firebaseui-idp-text': {
        fontSize: 'lg',
        color: 'body',
      },
    },

    // Material designs

    '.mdl-button': {
      maxWidth: 'none',
      ...shadowToBorder,

      '&:hover': {
        opacity: '.9',
      },

      '&:active': {
        transform: 'scale(.99)',
      },

      '&.mdl-button--colored': {
        background: 'primary.500',
      },

    },

    '.mdl-card': {
      boxShadow: 'none',
      padding: '4',
    },

  },
}

export default styles
