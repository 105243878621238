import { extendTheme } from '@chakra-ui/react'
import colors from './colors'
import firebaseui from './firebaseui'

const theme = extendTheme({
  colors: {
    // mate colors
    text: colors.blackcoral,
    primary: colors.turquoise,
    secondary: colors.hanblue,
    success: colors.turquoise,
    danger: colors.pastelpink,
    warning: colors.apricot,
    light: colors.yellowcrayola,
    gray: colors.blackcoral,
    lightgray: colors.gainsboro,
    lightestgray: colors.snow,
    // transparent colors
    darken: colors.darken,
  },

  fonts: {
    body: 'Montserrat, sans-serif',
    heading: 'Vidaloka, Georgia, serif',
    mono: 'Menlo, monospace',
  },
  components: {
    Button: {
      defaultProps: {
        colorScheme: 'primary',
      },
      sizes: {
        xl: {
          fontSize: 'xl',
          paddingX: '8',
          paddingY: '4',
        },
      },
    },
    Tag: {
      colorScheme: {
        light: {
          background: 'transparent',
          color: 'text-600',
        },
      },
    },
  },
  styles: {
    global: {
      'html, body, #root': {
        height: '100%',
      },
      ...firebaseui,
    },
  },
})

export default theme
