import { AUTHENTICATE, REPUDIATE } from './types'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE: {
      return {
        ...state,
        uid: action.payload.uid,
      }
    }
    case REPUDIATE: {
      return initialState
    }
    default:
      return state
  }
}
