import React from 'react'
import {
  Badge,
  Tag, TagLabel, TagLeftIcon, TagProps, ThemingProps,
} from '@chakra-ui/react'

import Icon from '../Icon'
import { BookingEventDisplay } from '../../types/booking'

interface CalendarEventProps {
  event: BookingEventDisplay,
  hideComment?: boolean,
  onClick: () => void,
}

type GetStylePropsArgs = Pick<ThemingProps, 'variant'> & {
  event: BookingEventDisplay
}

const getStyleProps = ({ variant, event }: GetStylePropsArgs) => {
  if (variant === 'outline') {
    return {
      textColor: 'text.900',
      iconColor: event.meta.colors.contrast,
    }
  }
  return {
    backgroundColor: event.meta.colors.main,
    textColor: event.meta.colors.contrast,
    iconColor: 'white',
  }
}

const CalendarEvent = (props: CalendarEventProps & TagProps) => {
  const {
    event,
    variant,
    hideComment,
    onClick,
    // ...rest
  } = props
  const {
    comment,
    type,
  } = event
  const {
    iconColor,
    backgroundColor,
    textColor,
  } = getStyleProps({ variant, event })

  return (
    <Tag
      colorScheme="grey"
      backgroundColor={backgroundColor}
      textColor={textColor}
      opacity={event.meta.isOwn ? 1 : '.6'}
      display="flex"
      title={event.meta.title}
      variant={variant}
      onClick={onClick}
      cursor="pointer"
    >
      <TagLeftIcon as={Icon} name={type} color={textColor} />
      <Badge colorScheme="blackAlpha" fontWeight="bold" fontSize=".9em">
        {`x${event.rooms}`}
      </Badge>
      <TagLabel paddingX={2}>{event.meta.title}</TagLabel>

      {
        comment && !hideComment ? (
          <Icon name="chat" variant="outline" color={iconColor} />
        ) : null
      }
    </Tag>
  )
}

CalendarEvent.defaultProps = {
  hideComment: false,
}

export default CalendarEvent
