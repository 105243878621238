import React from 'react'
import { getTypeDefinition } from '../../../constants/booking'
import Icon from '../../Icon'
import Illustration from '../../Illustration'

import BaseValue from './Booking.Values.Base'

const ValueType = (props) => {
  const {
    value, variant, iconSize,
  } = props
  const {
    label = 'Logement',
    icon = 'dots',
    illustration = 'seat',
  } = getTypeDefinition(value) || {}

  // eslint-disable-next-line no-console
  if (!icon) console.log('No icon provided')

  return (
    <BaseValue {...props} label={label}>
      {
        variant === 'icon'
          ? <Icon fontSize={iconSize} name={icon} lineHeight="inherit" />
          : <Illustration name={illustration} size={iconSize} margin="0 auto" />
      }
    </BaseValue>
  )
}
ValueType.propTypes = BaseValue.propTypes
ValueType.defaultProps = {
  ...BaseValue.defaultProps,
  color: 'warning.200',
  variant: 'icon',
  iconSize: 'inherit',
}
export default ValueType
