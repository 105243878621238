import { Place } from '../../../types/place'

export const getPlace = (store: any, placeId: string) => store.place[placeId]

export const getPlaces = (store: any, placeIds: string[]) => {
  const initialValue: Place[] = []
  return placeIds.reduce(
    (accu, placeId) => {
      const place = store.place[placeId]
      if (place !== undefined) accu.push(place)
      return accu
    },
    initialValue,
  )
}
