import React from 'react'
import {
  Flex, FormControl, FormErrorMessage, SimpleGrid, Text, Textarea,
} from '@chakra-ui/react'
import Footer from './Booking.Step.Footer'
import { propTypes, defaultProps } from './types'
import NumberRow from './Booking.Step.NumberRow'
import { getTypeDefinition } from '../../../../constants/booking'
import Icon from '../../../Icon'

const BookingStepDetails = ({
  stepper,
  register,
  formState,
  values,
}) => {
  const { roomLabel = 'Chambres/Emplacements' } = getTypeDefinition(values.type) || {}
  const { errors } = formState

  return (
    <>
      <Flex
        margin="0 auto"
        direction={['column', 'row']}
        wrap
      >
        <SimpleGrid
          borderRight={['none', '2px solid']}
          borderColor={['none', 'gray.200']}
          padding={4}
          direction="column"
          gap={2}
        >
          <NumberRow
            label={roomLabel}
            name="rooms"
            register={register}
            errors={errors}
          >
            <Icon name={values.type} />
            <Text>{roomLabel}</Text>
          </NumberRow>
          <NumberRow
            name="people"
            register={register}
            errors={errors}
          >
            <Icon name="people" />
            <Text>Adultes/Enfants</Text>
          </NumberRow>
          <NumberRow
            name="babies"
            register={register}
            errors={errors}
          >
            <Icon name="baby" />
            <Text>Bébé(s) -3ans</Text>
          </NumberRow>
        </SimpleGrid>
        <FormControl
          isInvalid={errors.comment}
          padding={4}
        >
          <Textarea
            {...register('comment')}
            placeholder="Saisissez un commentaire ici (optionnel)"
            height="100%"
          />
          <FormErrorMessage display="block" textAlign="right">
            {errors.comment ? errors.comment.message : ''}
          </FormErrorMessage>
        </FormControl>
      </Flex>
      <Footer
        formState={formState}
        stepper={stepper}
        isValid={formState.isValid}
      />
    </>
  )
}

BookingStepDetails.propTypes = propTypes
BookingStepDetails.defaultProps = defaultProps

export default BookingStepDetails
