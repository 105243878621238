import { SET_PLACE, SET_PLACES } from './types'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PLACE: {
      const { uid } = action.payload
      return {
        ...state,
        [uid]: action.payload,
      }
    }
    case SET_PLACES: {
      const places = action.payload
      return places.reduce(
        (accu, place) => ({
          ...accu,
          [place.uid]: {
            ...state[place.uid],
            ...place,
          },
        }),
        state,
      )
    }
    default:
      return state
  }
}
