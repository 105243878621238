/* eslint-disable import/prefer-default-export */

import vanSrc from './assets/van.svg'
import faviconSrc from './assets/favicon.svg'
import houseSrc from './assets/house.svg'
import tentSrc from './assets/tent.svg'
import tentVanSrc from './assets/tent-van.svg'
import seatSrc from './assets/seat.svg'

const ILLUSTRATION_SRC = {
  favicon: { src: faviconSrc },
  van: { src: vanSrc },
  house: { src: houseSrc },
  tent: { src: tentSrc },
  tentvan: { src: tentVanSrc },
  seat: { src: seatSrc },
}

export const getSrc = (name) => {
  const asset = ILLUSTRATION_SRC[name]
  if (!asset) throw new Error(`Illustration "${name}" does not exist.`)
  return asset.src
}
