import React from 'react'
import PropTypes from 'prop-types'
import db from '../../../init/db'
import Form from '../Form'
import { Booking, BookingCreate } from '../../../types'

const BookingEdit = ({
  placeId,
  values = {},
  onCancel,
  onSuccess,
}) => {
  const { uid } = values

  const handleSave = (newValues) => (
    uid
      ? db.booking.update(uid, placeId, newValues)
      : db.booking.create(newValues)
  )

  return (
    <Form
      placeId={placeId}
      onSave={handleSave}
      onCancel={onCancel}
      onSuccess={onSuccess}
      initialValues={values}
    />
  )
}

BookingEdit.propTypes = {
  placeId: PropTypes.string.isRequired,
  values: PropTypes.oneOfType([Booking, BookingCreate]),
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
}

BookingEdit.defaultProps = {
  values: {},
  onCancel: null,
  onSuccess: null,
}

export default BookingEdit
